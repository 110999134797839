.footer-reassurance-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px auto 30px;
    &.slick-initialized {
        display: block;
    }
    .slick-dots {
        @include flex-row-nowrap(center, center);
        gap: 10px;
        margin: 15px 0 40px;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            border: none;
            color: transparent;
            background: $grey;
            position: relative;
            width: 37px;
            height: 4px;
            transition: 200ms all $ease-in-out;

            &:focus-visible {
                outline: 2px solid $primary-color;
            }
            @include on-event {
                background: $primary-color;
            }
        }
        .slick-active {
            button {
                background: $primary-color;
            }
        }
    }
}
.footer-reassurance {
    display: flex;
    flex-flow: column nowrap;
    background: none;
    align-items: center;
    @include on-event {
        text-decoration: none;
    }
    @include max-width($mediuml) {
        width: 45%;
        margin-bottom: 6px;
    }
    @include max-width($small) {
        width: 48%;
    }
}
.slick-initialized .slick-slide.footer-reassurance {
    display: flex;
}
.footer-reassurance-icon {
    background: $light-grey;
    height: 57px;
    width: 57px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 57px;
    margin-bottom: 9px;
    @include max-width($small) {
        height: 80px;
        width: 80px;
    }
}
.footer-reassurance-svg {
    // margin: 0 25px;
    max-width: 36px;
    max-height: 36px;
    @include max-width($small) {
      
    }
}
.footer-reassurance-text-container {
    max-width: 165px;
    text-align: center;
}
.footer-reassurance-title {
    display: block;
    @include font-content(14);
    color: $secondary-color;
    @include max-width($small) {
        @include font-content(16);
    }
}

.column-reassurance {
    background-color: white;
    border: 1px solid #eaeaea;
    text-align: center;
    padding: 20px 0 5px;
    margin-top: 15px;
    margin-bottom: 27px;
    transition: 400ms border $ease-in-out;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    &:after {
        content: '';
        @include abs-position(-15px, 0, auto, 0);
        width: 100%;
        height: 1px;
        background-color: #eaeaea;
    }
    @include on-event() {
        text-decoration: none;
        border: 1px solid $primary-color;
        .column-reassurance-top-title {
            color: $primary-color;
        }
        .column-reassurance-description {
            color: $primary-color;
        }
    }
}

.column-reassurance-icon {
    margin-bottom: 10px;
    .replaced-svg {
        path {
            fill: $primary-color !important;
        }
    }
}
.column-reassurance-top-title {
    @include font-title(20);
    display: block;
    transition: color 400ms $ease-in-out;
    color: #616161;
    line-height: 1.2;
}
.column-reassurance-bottom-title {
    @include font-title(24);
    display: block;
    color: $primary-color;
    transition: color 400ms $ease-in-out;
    line-height: 1.2;
}
.column-reassurance-description {
    display: block;
    @include font-content(12);
    text-transform: uppercase;
    color: #616161;
    min-height: 21px;
    line-height: 1;
}
.column-reassurance-text-container {
    .separator {
        background-size: 300px;
    }
}
