/* needed for old browsers */
dialog {
  display: block;
  border: 0;
}
/* removes scroll when modal is opened */
.no-scroll {
  overflow: hidden;
}
/* another modal styling example */
/* tooltip modal for it’s easy button */

.simple-modal-overlay[data-background-click='disabled'] {
  cursor: auto;
}

.simple-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  z-index: 9;
  cursor: pointer;
}

.simple-modal {
  left: 50%;
  right: auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  height: auto;
  max-height: 90vh;
  z-index: 10;
  position: fixed;
  width: 520px;
  max-width: 100%;
  padding: 25px;
  background: $red;
  color: $white;
  @include font-content(16);
  border: 0;
  overflow: auto;
  animation: slide-in 0.4s both;
  -webkit-animation: slide-in 0.4s both;
  @include max-width($small) {
    padding: 25px 15px;
  }
}
@keyframes slide-in {
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translate(-50%, -100%) rotateX(90deg);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) rotateX(0);
    opacity: 1;
  }
}
.simple-modal-close {
  position: absolute;
  top: 14px;
  right: 14px;
  background: transparent;
  color: $white;
  border: 0;
  cursor: pointer;
  padding: 5px;
  @include on-event {
    outline: 2px solid $white;
  }
  &:focus-visible {
    outline: 2px solid $white;
  }
}
.simple-modal-title {
  color: $white;
  @extend .small-uppercase-title;
  margin-top: 0;
}
