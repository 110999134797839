// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
//Signature DE
.experience-spiriit {
    margin: 10px 0;
    text-align: center;
    @include flex-row-wrap(center, center);
    gap: 8px;
    @include font-medium(14);
    @include max-width($small) {
        text-align: center;
        @include font-size(12);
    }
}

.spiriit-dot {
  transform-origin: center bottom;
  transform: translate3d(0, 0, 0);
}
.spiriit-dot--first {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.spiriit-dot--second {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.spiriit-dot--third {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.logo-spiriit-link {
  margin-bottom: 8px;
  @include on-event {
      .spiriit-dot {
          animation-duration: 700ms;
          animation-name: bounce-spiriit;
          animation-iteration-count: 1;
      }
  }
}
.logo-spiriit {
  path,
  polygon {
      fill: white; //Change to white for dark bg
  }
  .spiriit-blue {
      fill: white; // Change to white if bg color is not B/W
  }
}

.footer-container {
    position: relative;
    background: $secondary-color;
    color: #fff;
}
.footer-logo-img{
    @include max-width($small) {
        max-width: 100px;
    }
}
.container--footer {
    @include font-size(18);
    @include flex-row-nowrap(flex-start, stretch);
    @include max-width(1210px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @include max-width($mediuml) {
        padding: 0;
        flex-flow: column;
    }
    @include max-width($small) {
        @include font-size(12);
    }

    .footer-block {
        padding: 0 26px;
    }
    .footer-block-large {
        flex-basis: calc(4 / 9 * 100%);
        border-right: 1px solid $white;
    }
    .footer-block-small {
        flex-basis: calc(1 / 9 * 100%);
    }

    #footer_store_info {
        @include font-size(16);
        margin-bottom: 20px;
        margin-top: 25px;
        @include max-width($small) {
            @include font-size(12);
        }
        .footer-adresse {
            @include font-content(18);
            line-height: 1.4;
            margin: 40px 0 20px;
            @include max-width($small) {
                @include font-size(14);
            }
        }
        .link-with-icon {
            margin-bottom: 5px;
            font-weight: 400;
            .icon-container {
                background: $primary-color;

                svg {
                    path {
                        fill: $white;
                    }
                }
            }
            @include on-event {
                .icon-container {
                    background: $white;
                    svg {
                        path {
                            fill: $primary-color;
                        }
                    }
                }
            }
            @include max-width($small) {
                @include font-size(16);
            }
        }
    }
    .block-linkblock {
        margin: 25px 0 40px;
        a {
            position: relative;
            display: inline-block;
            @include font-content(20);
            text-decoration: none;
            color: white;
            @include max-width($small) {
                @include font-size(18);
            }
            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $primary-color;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 0.5s;
            }
            @include on-event {
                &:after {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
        }
    }
    .social-sharing {
        margin: 25px 40px 25px 0;
        @include max-width($small) {
            @include flex-column(flex-start,flex-start);
        }
        a {
            position: relative;
            display: inline-block;
            @include font-content(20);
            text-decoration: none;
            color: white;
            line-height: 2;
            @include max-width($small) {
                @include font-size(18);
            }
            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $primary-color;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 0.5s;
            }
            @include on-event {
                &:after {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
        }
    }
    .footer-legals-links {
        margin: 15px 0 20px;
        display: inline-flex;
        flex-flow: row wrap;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        &--desktop {
            @include max-width($mediuml) {
                display: none;
            }
        }
        &--mobile {
            display: none;
            @include max-width($mediuml) {
                display: inline-flex;
            }
            @include max-width($small) {
                @include font-size(14);
            }
        }
    }
    .footer-legal-link {
        color: $white;
        @include font-content(16);
        text-decoration: underline;
    }
    .block-newsletter-title {
        @include font-medium(18);
        color: white;
        line-height: 1.8;
        margin-bottom: 0;
        @include max-width($small) {
            @include font-size(14);
        }
    }
    .block-newsletter-description {
        @include font-content(18);
        color: #aeaeae;
        line-height: 1.4;
        @include max-width($small) {
            @include font-size(14);
        }
    }
    .block_newsletter {
        label {
            margin: 10px 0 10px 21px;
            @include font-content(16);
            @include max-width($small) {
                @include font-size(14);
            }
        }
    }
    .block-newsletter-input-container {
        position: relative;
        .block-newsletter-input {
            color: white;
            border-radius: 50px;
            background: black;
            border: 1px solid white;
            padding: 12px 60px 12px 20px;
            @include on-event {
                border: 1px solid $primary-color;
            }
            &:placeholder {
                color: #aeaeae;
            }
        }
        .button-newsletter-submit {
            @include abs-position(0, 0, 0, auto);
            @include flex-row-nowrap(center, center);
            height: 100%;
            width: 50px;
            background: transparent;
            border: none;
            border-radius: 50px;
            transition: background 200ms $ease-in-out;
            svg {
                width: 15px;
                height: auto;
                path {
                    fill: $primary-color;
                }
            }
            @include on-event {
                background: $white;
            }
            &:focus-visible {
                outline: 2px solid $primary-color;
            }
        }
    }
}
.footer-copyright-section {
    text-align: center;
}
.footer-copyright-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;
    @include max-width(1210px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @include max-width($small) {
        flex-flow: column nowrap;
    }
}

.copyright-left {
    p {
        @include font-medium(14);
        text-align: left;
        margin: 10px 0;
        @include max-width($small) {
            text-align: center;
            margin: 10px 0 0;
        }
    }
}
.copyright-right p {
    text-align: right;
    margin: 10px 0;
    @include font-medium(13);
    @include max-width($small) {
        text-align: center;
    }
}
.button--gotop {
    display: block;
    z-index: 2;
    background: $primary-color;
    @include abs-position(31px, 24px, auto, auto);
    svg {
        width: 56px;
        height: 56px;
    }
    @include max-width(1300px) {
        @include abs-position(40px, 10px, auto, auto);
        svg {
            width: 34px;
            height: 34px;
        }
    }
}
.footer-copyright-section {
    background: #202020;
    p {
        color: $white;
    }
}
// Container des SVG pour clippath (masques sur les images)
.inline-clippath-container {
    height: 0;
    overflow: hidden;
}
