// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element

 */
html {
  box-sizing: border-box;
  font-size: 62.5%; /* REM Hack: 1rem = 10px for the whole website now */
}

body {
  width: 100%;
  overflow-x: hidden;
  //Prevent Iphone shitty random horizontal scroll
  -webkit-overflow-scrolling: touch;
  background: $light-background;
}
//Corrige le scroll horizontal sur mobile
main {
  max-width: 100%;
  overflow: hidden;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: $links-color;
  text-decoration: none;
  transition: 200ms all ease-in-out;
  @include on-event {
    color: $links-hover-color;
    text-decoration: underline;
  }
}
button{
  cursor: pointer;
}
img {
  height: auto;
  max-width: 100%;
}
ul {
  list-style: none;
  padding: 0;
}

// Animate scrolling only if users don’t prefer reduced motion
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
  // Add some spacing between the target and the top of the viewport
  :target {
    scroll-margin-top: 100px;
  }
}

#primary {
  position: relative;
}

#notifications {
  text-align: center;
  @include font-content(16);
  ul {
    margin: 0;
  }
}
