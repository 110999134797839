// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

//**************** */ BASE ET TAILLES
.button {
  display: inline-block;
  color: $white;
  background: $primary-color;
  line-height: 1.2;
  @include font-bold(14);
  padding: 16px 20px;
  border-radius: 0;
  text-decoration: none !important;
  transition: all 200ms $ease-in-out;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 3px solid black;
  }
  @include on-event() {
    background: $secondary-color;
    color: $white;
    text-decoration: none;
  }
  @include max-width($mediuml) {
    @include font-size(12);
  }

  
}

.button--small {
  @include font-medium(12);
  padding: 12px;
}

//**************** */ COULEURS
// Exemple de bouton coloré avec ses déclinaisons icon
// A copier pour chaque couleur
.button--white {
  background: $white;
  color: $secondary-color;
  &.button--withicon,
  &.button--flex,
  &.button--inlineflex,
  &.button--witharrow {
    svg {
      path {
        fill: $primary-color;
      }
    }
  }
  @include on-event() {
    background: $light-grey;
    color: $secondary-color;
    &.button--withicon,
    &.button--flex,
    &.button--inlineflex,
    &.button--witharrow {
      svg {
        path {
          // fill: $secondary-color;
        }
      }
    }
  }
}
.button--black {
  background: $secondary-color;
  color: $white;
  &.button--withicon,
  &.button--flex,
  &.button--inlineflex,
  &.button--witharrow {
    svg {
      path {
        fill: $white;
      }
    }
  }
  @include on-event() {
    background: $primary-color;
    color: $white;
    &.button--withicon,
    &.button--flex,
    &.button--inlineflex,
    &.button--witharrow {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}
.button-white{
  background: $white;
  color: $black;
  padding: 8px 10px;
  font-size: 1.2rem;
  font-weight: 600;
}
.button--transparent-white {
  background: transparent;
  color: $white;
  border: 3px solid $white;
  &.button--withicon,
  &.button--flex,
  &.button--inlineflex,
  &.button--witharrow {
    svg {
      path {
        fill: $white;
        stroke: $white;
        &:not(.stroke-only){
          stroke-width: 0;
        }
        &.stroke-only {
          fill: transparent;
          stroke: $white;
        }
      }
    }
  }
  @include on-event() {
    background: $white;
    color: $secondary-color;
    &.button--withicon,
    &.button--flex,
    &.button--inlineflex,
    &.button--witharrow {
      svg {
        path {
          fill: $secondary-color;
          stroke: $secondary-color;
          &:not(.stroke-only){
            stroke-width: 0;
          }
          &.stroke-only {
            fill: transparent;
            stroke: $secondary-color;
          }
        }
      }
    }
  }
  @include max-width($small){
    border: 2px solid $white;
  }
}
.button--transparent-red {
  background: transparent;
  color: $secondary-color;
  border: 3px solid $primary-color;
  &.button--withicon,
  &.button--flex,
  &.button--inlineflex,
  &.button--witharrow {
    svg {
      path {
        fill: $primary-color;
      }
    }
  }
  @include on-event() {
    background: $primary-color;
    color: $white;
    &.button--withicon,
    &.button--flex,
    &.button--inlineflex,
    &.button--witharrow {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
  @include max-width($small){
    border: 2px solid $primary-color;
  }
}

//**************** */ DÉCLINAISONS DES BOUTONS
.button--withicon,
.button--flex,
.button--inlineflex {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  
  svg {
    margin-right: 10px;
    path {
      transition: 200ms all $ease-in-out;
      fill: white;
    }
  }
  span {
    white-space: nowrap;
  }
}
.button--witharrow {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: left;
  @include on-event {
    svg {
      transform: translateX(5px);
    }
  }
  svg {
    transform: translateX(0);
    transition: transform 300ms $ease-in-out-fast;
    margin-left: 15px;
    @include max-width($mediuml) {
      margin-left: 10px;
    }
    path {
      transition: 200ms fill $ease-in-out;
      fill: white;
    }
  }
  span {
    white-space: nowrap;
  }
}
.button--rounded {
  border-radius: 50px;
}

.button-icon {
  width: 100%;
  max-width: 22px;
  max-height: 22px;
  min-width: 18px;
}
.button--centered {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.button--fullsize {
  display: block;
  text-align: center;
  width: 100%;
}
.link-with-icon {
  @include flex-row-nowrap(flex-start, center);
  color: $primary-color;
  @include font-medium(18);
  text-decoration: underline;
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $light-grey;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    transition: background 200ms $ease-in-out;
    margin-right: 10px;
    svg {
      path {
        fill: $black;
        transition: fill 200ms $ease-in-out;
      }
    }
  }
  @include on-event {
    .icon-container {
      background: $red;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }
  &.link-with-icon--black{
    color: $black;
    text-decoration: none;
    svg{
      margin-right: 20px;
      transform: translateY(-1px);
      transition: transform 400ms $ease-in-out-fast;
    }
    @include on-event {
      text-decoration: underline;
        svg {
          transform: translateY(4px);
        }
      }
    }
    &:focus-visible{
      border: 3px solid $black;
    }
  }
.button--disabled{
  background-color: $grey !important;
  color: $dark-grey !important;
  border: none;
  svg {
   
    path {
      fill: $dark-grey !important;
      stroke: $dark-grey !important;
      &:not(.stroke-only){
        stroke-width: 0;
      }
      &.stroke-only {
        fill: transparent !important;
        stroke: $dark-grey !important;
      }
    }
  }
  @include on-event{
    background-color: $grey !important;
    color: $dark-grey !important;
    border: none;
    svg {
      max-width: 13px;
      min-width: 13px;
      path {
        fill: $dark-grey !important;
        stroke: $dark-grey !important;
        &:not(.stroke-only){
          stroke-width: 0;
        }
        &.stroke-only {
          fill: transparent !important;
          stroke: $dark-grey !important;
        }
      }
    }
  }
}