// -----------------------------------------------------------------------------
// Base styles taken from kelvelo.com
// -----------------------------------------------------------------------------

.blockcart-modal {
  padding-right: 0 !important;
  .modal-add-to-cart {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) !important;
    max-width: 900px;
    width: 90%;
  }
  .modal-header {
    border-bottom: none;
    padding: 20px 30px;
  }
  .modal-title {
    &#blockcart-modal-label {
      color: $green;
      @include font-semibold(16);
      text-align: left;
      .available-svg {
        display: inline-block;
        circle {
          fill: $green;
        }
      }
    }
  }
}
.modal-close-button {
  @include abs-position(-14px, -14px);
  border: none;
  background: $primary-color;
  color: white;
  width: 33px;
  height: 45px;
  transition: background 300ms $ease-out;
  @include max-width($small) {
    @include abs-position(-14px, 0);
  }
  &:before,
  &:after {
    position: absolute;
    left: 16px;
    top: 15px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: white;
    transition: background 300ms $ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  @include on-event() {
    background: $secondary-color;
  }
  &:focus {
    background: $secondary-color;
  }
}
.modal-body-cart {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 30px 0;
}
.modal-image {
  max-width: 176px;
  width: 100%;
  flex-grow: 0;
  @include max-width($small) {
    max-width: 82px;
  }
}
.modal-content-body {
  margin-left: 25px;
  margin-top: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  @include max-width($small) {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10px;
    margin-top: 0;
  }
  .modal-product-name {
    @include font-title(17);
    color: $secondary-color;
    line-height: 1.2;
    margin: 4px 0 4px;
  }
  .modal-product-variant {
    margin-bottom: 0;
    @include font-medium(16);
  }
}
.modal-product-price {
  @include max-width($small) {
    margin-top: 10px;
  }
  .product-price-discount {
    // min-height: 7px;
  }
  .regular-price {
    display: inline-block;
    color: $secondary-color;
    position: relative;
    @include font-title(14);
    @include max-width($small) {
      @include font-size(12);
    }
    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background: $primary-color;
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateY(-50%) rotate(-15deg);
    }
  }
  .product-flag {
    display: inline-block;
    @include font-medium(14);
    margin: 0 0 0 8px;
    @include max-width($small) {
      @include font-size(12);
    }
  }
  .product-flag-content {
    padding: 1px 5px 1px 4px;
  }
  .price {
    display: block;
    line-height: 1;
    text-align: left;
    margin-bottom: 13px;
    color: $secondary-color;
    @include font-title(20);
    @include max-width($small) {
      @include font-size(16);
    }
    &.current-price-discount {
      color: $red;
    }
  }
}
.modal-footer {
  border: none;
  padding: 0 30px 10px;
  hr.category-header-separator {
    margin-top: 0;
  }
  .modal-footer-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include max-width($small) {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    .button {
      @include max-width($small) {
        width: 80%;
        padding: 12px 14px;
        margin: 0 auto 10px;
        .button-content {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
