// -----------------------------------------------------------------------------
// This file contains all application-wide Sass animations
// -----------------------------------------------------------------------------

/* ----------------------------------------------
 * Generated by Animista on 2021-3-10 16:50:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@mixin scale-in-center {
    -webkit-animation: scale-in-center 400ms $ease-out both;
    animation: scale-in-center 400ms $ease-out both;
}

@-webkit-keyframes scale-in-center-pf-modal {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0.8);
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center-pf-modal {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0.8);
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@include max-width($small) {
    @-webkit-keyframes scale-in-center-pf-modal {
        0% {
            -webkit-transform: translate(-50%, -20px) scale(0.8);
            transform: translate(-50%, -20px) scale(0.8);
            opacity: 0;
        }
        100% {
            -webkit-transform: translate(-50%, -20px) scale(1);
            transform: translate(-50%, -20px) scale(1);
            opacity: 1;
        }
    }
    @keyframes scale-in-center-pf-modal {
        0% {
            -webkit-transform: translate(-50%, -20px) scale(0.8);
            transform: translate(-50%, -20px) scale(0.8);
            opacity: 0;
        }
        100% {
            -webkit-transform: translate(-50%, -20px) scale(1);
            transform: translate(-50%, -20px) scale(1);
            opacity: 1;
        }
    }
}
@mixin scale-in-center-pf-modal {
    -webkit-animation: scale-in-center-pf-modal 400ms $ease-out both;
    animation: scale-in-center-pf-modal 400ms $ease-out both;
}

@keyframes appear-from-bottom {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translate3d(0);
        opacity: 1;
    }
}
@keyframes appear-from-top {
    0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translate3d(0);
        opacity: 1;
    }
}

@keyframes addtocart-appear {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    100% {
        transform: translateY(100%);
        opacity: 1;
    }
}

@keyframes slide-from-right {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.slide-from-right {
    animation: 400ms $ease-in-out slide-from-right both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// https://css-tricks.com/a-trick-that-makes-drawing-svg-lines-way-easier/
// The path need pathLength="1" on the svg element
// Also, those css rules :
// stroke-dasharray: 1;
// stroke-dashoffset: 1;
// animation: drawline 1s ease-in-out;
@keyframes drawline {
    from {
        stroke-dashoffset: 1;
    }
    to {
        stroke-dashoffset: 0;
    }
}

// Logo spiriit
@keyframes bounce-spiriit {
    from,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0) scaleY(1);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -2px, 0) scaleY(1.1);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -1px, 0) scaleY(1.05);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        transform: translateZ(0) scaleY(0.95);
    }
    90% {
        transform: translate3d(0, -1px, 0) scaleY(1.02);
    }
}
