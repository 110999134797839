// -----------------------------------------------------------------------------
// Base styles taken from kelvelo.com
// -----------------------------------------------------------------------------

.product-miniature {
  background-color: $white;
  position: relative;
  border: 1px solid #f6f6f6;
  padding: 0;
  max-width: 100%;
  margin: 8px 0;
  transition: all 400ms $ease-out;
  box-shadow: $shadow-none;
  &:focus-within,
  &:focus,
  &:hover,
  &:active {
    box-shadow: $shadow-hover-spread;
  }
  &.is-outofstock {
    .hiaddtocart {
      // display: none !important;
    }
  } //.is-outofstock
}
.product-card {
  width: 100%;
  @include flex-row-nowrap(space-between, stretch);
  padding: 15px 15px 25px;
  gap: 15px;
  @include max-width(800px){
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 15px;
    
  }
}

.miniature-label {
  @include font-medium(10);
  text-transform: uppercase;
  display: block;
  color: $black;
  padding-bottom: 5px;
  @include max-width(800px){
    display: none;
  }
  &:not(:first-of-type) {
    padding-top: 15px;
  }
}
.product-thumbnail-container {
  align-self: stretch;
  justify-self: stretch;
  @include flex-column(center, flex-start);
  flex-grow: 0;
  flex-shrink: 0;
  .miniature-label {
    // margin-top: 0;
  }
  .product-thumbnail-img {
    width: 98px;
    margin: auto 0 auto;
  }
}

.product-miniature-description {
  flex-grow: 1;
  @include flex-row-nowrap(space-between, flex-start);
  gap: 40px;
  @include max-width($large){
    gap: 20px;
  }
  @include max-width(800px){
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: unset;
  }
}
.product-reference-container {
  flex-grow: 1;
}
.product-reference-origine-container{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 130px;
  @include max-width(800px){
    flex-basis: unset;
  }
}
.product-title {
  line-height: 1.4;
  @include font-bold(14);
  margin-bottom: 0;
  display: block;
}
.product-subtitle {
  @include font-bold(14);
  color: $dark-grey;
  transition: color .2s ease-in-out;
  display: block;
}
.product-reference-alder{
  @include font-bold(14);
  color: $primary-color;
  display: block;
}
.product-reference-origine {
  @include font-bold(14);
  &.product-reference-origine--empty{
    @include font-medium(12);
    color: $dark-grey;
    transition: color .2s ease-in-out;
  @include max-width(800px){
    display: none;
  }
  }
  
}
.product-miniature-link {
  @include flex-row-nowrap(flex-start, stretch);
  color: $secondary-color;
  text-decoration: none;
  gap: 15px;
  margin-right: 3%;
  flex-grow: 1;
  @include max-width(800px){
    margin-right:0;
  }
  @include on-event {
    text-decoration: none;
    color: $primary-color;
    .product-subtitle {
      @include font-bold(14);
      color: $primary-color;
    }
    .product-reference-origine.product-reference-origine--empty{
        color: $primary-color;
      }
    
  }
  &:focus-visible {
    outline: 2px solid $primary-color;
  }
  
  .regular-price {
    display: inline-block;
    color: $secondary-color;
    position: relative;
    @include font-medium(15);
    @include max-width($small) {
      @include font-size(11);
    }
    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background: $primary-color;
      @include abs-position(50%, auto, auto, 0);
      transform: translateY(-50%) rotate(-15deg);
    }
  }

  .price {
    display: block;
    line-height: 1;
    @include font-title(20);
    color: $secondary-color;
    @include max-width(800px){
      margin-top: 15px
    }
    @include max-width($small) {
      @include font-size(18);
    }
    
    &.current-price-discount {
      color: $primary-color;
    }
  }
}
.product-miniature-price-right {
  width: 100%;
  max-width: 260px;
  @include max-width(800px){
    max-width: unset;
  }
  &.product-miniature-price-right--notconnected{
    @include flex-column(center,center);
    
  }

  .product-availability{
    display: inline-flex;
    gap: 1rem;
  }
}
.button--devis-miniature{
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.product-livraison {
  display: block;
  @include font-bold(14);
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.4;
}
.product-livraison--orange {
  color: $orange;
}
.product-livraison--rouge {
  color: $primary-color;
}
.product-livraison--vert {
  color: $green;
}

.hiaddtocart{
  @include flex-row-nowrap(space-between,stretch);
  margin-bottom: 5px;
   @include max-width(800px){
        margin-bottom: 10px;
      }
  .product-quantity{
    flex-grow: 0;
    margin-right: 5px;
    @include max-width(800px){
      margin-right: 10px;
    }
    .bootstrap-touchspin{
      position: relative;
      @include flex-row-nowrap(center, stretch);
      color: $black;
      border: 2px solid #eeeeee;
      background: white;
      border-radius: 0;
      padding: 0;
      width: 100%;
      transition: border 400ms $ease-out;
      @include font-bold(16);
      @include on-event {
        border: 2px solid $primary-color;
        outline: none;
      }
      &:focus-within {
        border: 2px solid $primary-color;
      }
    
    }
    .hi-quantity{
      border-top: none;
      border-bottom: none;
      border-right: 2px solid transparent;
      border-left: 2px solid transparent;
      padding: 0;
      text-align: center;
      min-height: 54px;
      width: 35px;
      
      &:focus{
        border-right: 2px solid $primary-color;
        border-left: 2px solid $primary-color;
      }
      @include max-width(800px){
        min-height: 45px;
        width: 45px;
      }
    }
    
    .btn-touchspin{
      background: $white;
      color: $primary-color;
      border: none;
      padding: 0 6px;
      text-align: center;
      height: 100%;
      transition: all 400ms $ease-out;
      @include max-width(800px){
        padding: 0 12px;
      }
      @include on-event{
        background: $primary-color;
        color: $white;
        
      }
    }
  }
  .add-to-cart{
    flex-grow: 1;
    padding: 8px 10px 8px 5px;
    svg{
      max-width: 13px;
      min-width: 13px;
      path {
        fill: $white;
        stroke: $white;
        &.stroke-only {
          fill: transparent;
          stroke: $white;
        }
      }
    }
  }
}