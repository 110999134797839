// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
  @include max-width($small) {
    padding-left: 10px; /* 3 */
    padding-right: 10px; /* 3 */
  }
}
.container--wide {
  max-width: 1920px; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 32px; /* 3 */
  padding-right: 32px; /* 3 */
  width: 100%; /* 1 */
  @include max-width($mediuml) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include max-width($small) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.container--medium {
  max-width: 940px; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
  @include max-width($small) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.container--small {
  max-width: 840px; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
  @include max-width($small) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

.is-hidden {
  display: none !important;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; // Many screen reader and browser combinations announce broken words as they would appear visually.

  &:focus {
    background-color: black;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: white;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; // Above WP toolbar.
  }
}

/* Do not show the outline on the skip link target. */
#primary[tabindex='-1']:focus {
  outline: 0;
}

.relative {
  position: relative;
}

// .couleur,.color{
//   color: $couleur:
// }

.desktop-only, .desktop-only--small{
  @include max-width($small){
    display: none;
  }
}
.desktop-only--mediuml, .desktop-only--medium{
  @include max-width($mediuml){
    display: none;
  }
}