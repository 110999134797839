// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

#header {
    margin-top: 158px;
    @include max-width($small) {
        margin-top: 208px;
    }
}
.header-logo {
    @include max-width($mobilemenu) {
        margin: 0 auto;
        order: 1;
    }
    h1 {
        margin: 0;
        padding: 0;
        font-size: 12px;
    }
}
.header-logo-img {
    max-width: 75px;
    @include max-width($mobilemenu) {
        max-width: 75px;
    }
    @include max-width($small) {
        max-width: 75px;
    }
}
.header-top {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 4;
    .container {
        position: relative;
        z-index: 3;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        @include max-width($mobilemenu) {
            flex-flow: row wrap;
            padding-top: 10px;
            z-index: 1;
            box-shadow: 0px 3px 3px #8080804f;
            padding-top: 5px;
            padding-bottom: 0;
        }
    }
}
.mobile-empty-menu {
    display: none;
    @include max-width($mobilemenu) {
        display: block;
        order: 1;
        width: 105px;
        height: 1px;
    }
    @include max-width($small) {
        width: 89px;
    }
}
.header-searches-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @include max-width($mediuml) {
        flex-flow: row nowrap;
        gap: 10px;
        align-items: flex-start;
    }
    @include max-width($mobilemenu) {
        order: 3;
        width: 100%;
        background: $light-background;
        padding: 8px 0 15px;
        margin-top: 5px;
        align-items: center;
        position: relative;
        &:after {
            content: '';
            width: 100vw;
            left: -20px;
            right: -20px;
            top: 0;
            bottom: 0;
            z-index: 1;
            background: $light-background;
            position: absolute;
        }
    }
    @include max-width($small) {
        flex-flow: column nowrap;
        align-items: center;
        &:after {
            left: -10px;
            right: -10px;
        }
    }
}
body.nav-up:not(.mobile-menu-opened) {
    .header-searches-container {
        @include max-width($small) {
            display: none;
        }
    }
}
body.nav-down {
    .header-searches-container {
        @include max-width($small) {
            animation: 300ms $ease-in-out-fast appear-from-top;
        }
    }
}
.search-widget {
    min-width: 380px;
    @include max-width($mobilemenu) {
        z-index: 2;
    }
    @include max-width($small) {
        width: 100%;
        min-width: unset;
    }
    .search-widget__group {
        position: relative;
        .form-control {
            border: 1px solid $dark-grey;
            background-color: white;
            border-radius: 50px;
            @include font-content(16);
            padding: 8px 45px 8px 20px;
            line-height: 1;
            @include on-event {
                border-color: $primary-color;
            }
        }
        .search-widget__btn {
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary-color;
            border-radius: 50px;
            border: none;
            width: 32px;
            height: 32px;
            transition: background-color 400ms $ease-in-out;

            svg path {
                fill: $white;
            }
            @include on-event {
                background-color: $secondary-color;
            }
            &:focus-within {
                outline: 2px solid $black;
            }
        }
    }
}
.autocomplete-suggestion .autocomplete-suggestion {
    align-items: center;
}
.demande-recherche-link {
    @include font-content(14);
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    text-decoration: underline;
    @include max-width($mobilemenu) {
        z-index: 2;
    }
    @include max-width($small) {
        @include font-content(12);
    }
    @include on-event {
        svg {
            transform: scale(1.05) rotate(-6deg);
        }
    }
    &:focus-within {
        outline: 2px solid $black;
    }
    svg {
        width: 12px;
        margin-right: 6px;
        transform-origin: center;
        transform: scale(1) rotate(0);
        transition: transform 200ms ease-in-out;
        path {
            fill: $primary-color;
        }
    }
}
.header-right-nav {
    position: relative;
    z-index: 2;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    @include max-width($mobilemenu) {
        position: static;
        order: 2;
    }
}
.user-info {
    margin-right: 15px;

    .account {
        @include flex-row-nowrap(flex-start, center);
        color: $black;
        @include max-width($mobilemenu){
            flex-flow: column nowrap;
        }
        @include on-event {
            color: $black;
            .account-nav-icon-container {
                background: darken($dark-grey, 10%);
                border: 1px solid darken($dark-grey, 10%);
                svg {
                    transform: scale(1.1);
                    path,
                    circle {
                        fill: $white;
                        stroke: $white;
                    }
                }
            }
        }
    }
    .account-nav-icon-container {
        background: $grey;
        border: 1px solid $dark-grey;
        border-radius: 50px;
        width: 45px;
        height: 45px;

        @include flex-row-wrap(center, center);
        transition: all 200ms ease-in-out;
        @include max-width($small) {
            width: 37px;
            height: 37px;
        }
        svg {
            transform-origin: center;
            transform: scale(1);
            transition: transform 200ms ease-in-out;
            width: 19px;
            path,
            circle {
                fill: transparent;
                stroke: $black;
                stroke-width: 0.5;
                stroke-miterlimit: 10;
                transition: all 200ms ease-in-out;
            }
        }
    }
}
.icon-nav-element-txt-container {
    margin-left: 5px;
    @include max-width($mobilemenu) {
        margin-left: 0
    }
    
}
.icon-nav-element-txt--myaccount {
    @include font-semibold(9);
    @include max-width($mobilemenu) {
        font-weight: 400
    }
}
.icon-nav-element-txt--name {
    @include font-content(9);
    @include max-width($mobilemenu) {
        display: none;
    }
}

.blockcart {
    position: relative;
    &.inactive {
        .blockcart__count {
            display: none;
        }
    }
    &.active {
        .icon-nav-element-link--cart {
            svg {
                transform: scale(1.1);
                path {
                    stroke: $black;
                }
            }
        }

        .icon-nav-element-link--cart {
            @include on-event {
                background: darken($dark-grey, 10%);
                border: 1px solid darken($dark-grey, 10%);
                svg {
                    path {
                        fill: $white;
                        stroke: $white;
                        &.stroke-only {
                            fill: transparent;
                        }
                    }
                }
            }
        }
    }
}
.icon-nav-element-link--cart {
    background: $grey;
    border: 1px solid $dark-grey;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    @include flex-row-wrap(center, center);
    transition: all 200ms ease-in-out;
    @include max-width($small) {
        width: 37px;
        height: 37px;
    }
    svg {
        transform-origin: center;
        transform: scale(1);
        transition: transform 200ms ease-in-out;
        path,
        circle {
            fill: transparent;
            stroke: $black;
            stroke-width: 0.5;
            stroke-miterlimit: 10;
            transition: all 200ms ease-in-out;
        }
    }
}

.blockcart__count {
    background: $primary-color;
    color: $white;
    @include font-bold(12);
    border-radius: 20px;
    position: absolute;
    top: -5px;
    right: 0;
    display: block;
    min-width: 22px;
    height: 22px;
    text-align: center;
    z-index: 1;
    line-height: 20px;
    @include max-width($small) {
        top: -10px;
        right: -5px;
    }
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

// Gestion du header flottant désactivée car la barre est trop haute sur mobile
// Si réactivation ! Prendre en compte le code commenté dans body.mobile-menu-opened
// .l-header {
//   @include max-width($mobilemenu) {

//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: auto;
//     z-index: 5;
//     transform: translateY(0);
//     transition: transform 300ms $ease-in-out-faster;
//   }

//   &.nav-up {
//       @include max-width($mobilemenu) {
//         transform: translateY(-100%);
//       }

//   }
// }
// #primary{
//   @include max-width($mobilemenu) {
//     padding-top: 200px;
//   }
// }
body.mobile-menu-opened {
    @include max-width($mobilemenu) {
        overflow: hidden;
        // .l-header {
        //   bottom: 0;
        //   // height: 100%;
        //   position: fixed;
        // }
    }
}
.top-header-container {
    padding-top: 9px;
    padding-bottom: 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    @include max-width($mobilemenu) {
        display: none;
    }
}
.top-header-right {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
.header-logo-title {
    margin: 0;
}
.header-logo {
    @include flex-row-nowrap;
    &:focus-visible {
        outline: 3px solid $black;
    }
    @include max-width($mobilemenu) {
        z-index: 4;
    }
}
.header-container {
    display: flex;
    position: relative;
    justify-content: flex-start;
    padding-bottom: 15px;
    @include max-width($mobilemenu) {
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: space-between;
        position: static;
        // Bug bizarre, quand transform est actif, le bloc se comporte comme un
        // élément positionné et "contient" ses enfants positionnés...
        // transform: translateY(0);
        transition: transform 400ms $ease-in-out-faster;
    }
    .button-contact-mobile {
        display: none;
        @include max-width($mobilemenu) {
            z-index: 4;
            display: inline-flex;
            order: 3;
        }
    }
}
nav.main-navigation {
    position: fixed;
    top: 104px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 4;
    @include flex-row-nowrap(center, center);
    background-color: $light-background;
    border-bottom: 1px solid #ddd;
    @include max-width($mobilemenu) {
        border: none;
    }
    @include max-width($small) {
        top: 56px;
    }
    ul.main-navigation {
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        @include max-width($mobilemenu) {
            padding: 0 0 30px;
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .menu-item {
            display: inline-block;
            text-align: center;
            flex-grow: 1;
            flex-basis: 20%;
            @include max-width($mobilemenu) {
                padding: 0;
                width: 100%;
                flex-grow: unset;
                flex-basis: unset;
                border-bottom: 1px solid $white;
                text-align: left;
            }
            &:not(:first-of-type) {
                // border-left: 1px solid $grey;
                @include max-width($mobilemenu) {
                    border-left: none;
                }
            }

            a {
                display: block;
                position: relative;
                padding: 17px 20px;
                color: $black;
                @include font-bold(16);
                transition: color 200ms $ease-in-out;
                line-height: 1.2;
                @include on-event() {
                    text-decoration: none;
                    color: $primary-color;
                    // background: $grey;
                }
                @include max-width($mobilemenu) {
                    padding: 9px 15px;
                    @include font-bold(20);
                    width: 100%;
                }
                &:focus-visible {
                    outline: 2px solid $black;
                }
            }
            &.menu-item-has-children {
                background: $light-background;
                position: relative;
                @include max-width($mobilemenu) {
                    padding-right: 25px;
                }
                &.is-opened {
                    @include max-width($mobilemenu) {
                        background: $red !important;

                        .sub-menu {
                            padding-bottom: 10px;
                            display: block !important;
                        }
                    }
                    a {
                        color: $white !important;
                        @include on-event {
                            background-color: $red;
                        }
                    }
                }
                > a {
                    &:after {
                        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='13.3' height='13.3' viewBox='0 0 13.3 13.3'%3E%3Cpath fill='%23cb2027' d='M12.3 5h-4V1c0-.6-.4-1-1-1H6a1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1v1.2c0 .6.4 1 1 1h4v4c0 .6.4 1 1 1h1.2c.6 0 1-.4 1-1v-4h4c.6 0 1-.4 1-1V6c.1-.5-.3-1-.9-1z'/%3E%3C/svg%3E%0A");
                        position: absolute;
                        color: $primary-color;
                        top: 52%;
                        right: 10%;
                        transform: translate(0, -50%) rotate(0);
                        transition: transform 400ms $ease-in-out-faster;
                        transform-origin: center;
                        @include max-width($mobilemenu) {
                            display: none;
                        }
                    }
                }

                @include on-event() {
                    background: $grey;
                    > a {
                        color: $red;
                        &:after {
                            transform: translate(-1px, -54%) rotate(90deg);
                        }
                    }
                    .sub-menu {
                        display: block;
                        @include max-width($mobilemenu) {
                            display: none;
                        }
                    }
                }
                &:focus-within {
                    background: $grey;
                    > a {
                        color: $red;
                        &:after {
                            transform: translate(-1px, -54%) rotate(90deg);
                        }
                    }
                    .sub-menu {
                        display: block;
                        @include max-width($mobilemenu) {
                            display: none;
                        }
                    }
                }
                .submenu-mobile-button {
                    display: none;
                    width: 28px;
                    height: 28px;
                    background-color: transparent;
                    transition: background-color 400ms $ease-in-out-faster;
                    border: none;
                    z-index: 1;
                    font-size: 0px;
                    padding: 0;
                    @include abs-position(8px, 5px);
                    @include max-width($mobilemenu) {
                        display: block;
                    }
                    &[aria-expanded='true'] {
                        background: $primary-color;
                        svg {
                            transform: translate(-50%, -50%) rotateZ(0);
                            path {
                                fill: $white;
                            }
                        }
                    }
                    svg {
                        transform: translate(-50%, -50%) rotateZ(180deg);
                        transition: transform 400ms $ease-in-out-faster;
                        z-index: 5;
                        width: 14px;

                        //Correction désespéré du bug sur safari qui fait que le svg ne s'affiche pas
                        @include abs-position(50%, auto, auto, 50%);
                        min-width: 14px;
                        max-width: 14px;
                        min-height: 13px;
                        max-height: 15px;
                        height: auto;
                        path {
                            fill: $primary-color;
                            transition: fill 400ms $ease-in-out-faster;
                        }
                    }
                    &:focus-visible {
                        outline: 2px solid $black;
                    }
                }
                .sub-menu {
                    display: none;
                    position: absolute;
                    padding: 12px 0;
                    margin: 0;
                    z-index: 1;
                    bottom: 0;
                    left: 0%;
                    transform: translate(0, 100%);
                    background: $grey;
                    -webkit-animation-name: fadeIn;
                    animation-name: fadeIn;
                    animation-duration: 100ms;
                    z-index: 3;
                    columns: 2;
                    column-rule: 1px solid $white;

                    @include max-width($mobilemenu) {
                        position: static;
                        min-height: unset;
                        background: unset;
                        padding-top: 0;
                        padding: 0;
                        transform: none;
                        columns: unset;
                        column-rule: unset;
                    }

                    .menu-item {
                        margin-right: 0;
                        display: block;
                        text-align: left;
                        break-inside: avoid-column;
                        @include max-width($mobilemenu) {
                            padding: 3px 0;
                            border-bottom: none;
                            text-align: right;
                        }
                        a {
                            color: $black;
                            @include font-bold(14);
                            padding: 14px 24px 17px;
                            min-width: 220px;
                            @include on-event() {
                                color: $primary-color;
                            }
                            @include max-width($mobilemenu) {
                                @include font-content(14);
                                padding: 0 0 0 20px;
                                margin: 8px 0;
                                text-align: right;
                                text-decoration: underline;
                                width: auto;
                                display: inline-block;
                            }
                        }
                    }
                }
                .menu-image {
                    position: absolute;
                    display: block;
                    left: 0;
                    transform: translateX(calc(-100% - 50px));
                    top: 16px;
                    pointer-events: none;
                    width: 225px;
                    max-width: 225px;
                    max-height: 250px;
                    @include max-width($mobilemenu) {
                        display: none;
                    }
                }
            }
        }
    }
    &.main-navigation--desktop {
        .toggle-menu-button {
            display: none;
            z-index: 4;
            width: 46px;
            height: 46px;
            background: white;
            border-radius: 50px;
            border: none;
            color: black;
            @include max-width($mobilemenu) {
                position: fixed;
                top: 28px;
                right: auto;
                bottom: auto;
                left: 10px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-flow: column nowrap;
            }

            &:focus-visible {
                outline: 3px solid $primary-color;
            }

            svg path {
                fill: $secondary-color;
            }
            &[aria-expanded='true'] {
                .hamburger {
                    display: none;
                }
            }
            &[aria-expanded='false'] {
                .croix {
                    display: none;
                }
            }
        }
        @include max-width($mobilemenu) {
            order: 1;
            margin-left: 0;
            ul.main-navigation {
                display: none;
                &.is-opened {
                    display: flex;
                    position: absolute;
                    top: 55px;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: calc(100vh - 160px);
                    z-index: 9;
                    background-color: $light-background;
                    overflow-y: auto;
                }
            }
        }
        @include max-width($small) {
            ul.main-navigation {
                &.is-opened {
                    height: calc(100vh - 207px);
                    top: 152px;
                }
            }
        }
    }
    .social-sharing {
        padding-top: 40px;
        .social-link {
            @include font-medium(20);
            width: auto;
            display: block;
            color: $black;
            @include on-event {
                color: $primary-color;
            }
        }
    }
    .footer-legals-links--mobile {
        display: none;
    }
}
.mobile-menu-only {
    display: none;
    width: 100%;
    padding: 40px 15px 0;
    @include max-width($mobilemenu) {
        display: block;
    }
}
.mobile-menu-static-buttons {
    padding-bottom: 40px;
    border-bottom: 1px solid $white;
    .link-with-icon {
        margin: 10px 0;
    }
}

.breadcrumb {
    display: block;
    list-style: none;
    padding-left: 0;
    line-height: 1.2;
}
.breadcrumb-item {
    margin-right: 25px;
    margin-bottom: 0px;
    color: $dark-grey;
    display: inline;
    @include font-medium(14);
    @include max-width($small) {
        @include font-size(10);
        margin-right: 10px;
    }

    a {
        color: $black;
        text-decoration: underline;
        display: inline-block;
        @include on-event {
            color: $primary-color;
        }
        &:focus-visible {
            outline: $white 2px solid;
        }
    }
}
