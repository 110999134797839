// -----------------------------------------------------------------------------
// Base styles taken from kelvelo.com
// -----------------------------------------------------------------------------

.main_wrapper .blog_post_feed {
  .block-image {
    width: 235px;
    @include max-width($mobilemenu) {
      width: 92%;
    }
    .thumb {
      z-index: 1;
    }
  }
  .post-category {
    color: $dark-grey;
    text-decoration: none;
    @include font-medium(14);
    text-transform: uppercase;
    display: inline;
    @include on-event {
      color: $primary-color;
      text-decoration: underline;
    }
  }
  .post-content-wrapper {
    padding-right: 10px;
    width: 75%;
    @include max-width($mobilemenu) {
      width: 92%;
    }
    .post-title {
      @include font-bold(18);

      text-transform: none;
      a {
        color: $black;
      }
    }
    .date {
      @include font-size(18);
      color: $secondary-color;
      text-transform: uppercase;
      line-height: 1;
      @include font-title;
      @include max-width($mobilemenu) {
        @include font-size(14);
      }
    }
    .detail {
      padding: 8px 0;
      @include font-content(14);
      color: $secondary-color;
      line-height: 1.4;
    }
    .readmore-blog {
      @include font-bold(14);
    }
  }
}

#primary .sidebar_wrapper .widget,
#primary .detail_wrapper .widget {
  box-shadow: none;
  border: none;
  .widget_title {
    height: auto;
    h2 {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .search {
    padding-top: 0;
    input {
      padding: 8px 50px 8px 12px;
      border-radius: 50px;
      border: 1px solid grey;

      @include on-event {
        border-color: $primary-color;
      }
    }
    button {
      border-radius: 50px;
    }
  }
}

.sidebar_wrapper .widget,
.detail_wrapper .widget {
  box-shadow: 0px 0px 5px #0000001a;
  border: 1px solid #f6f6f6;
}
.sidebar_wrapper .widget .widget_title h2,
.detail_wrapper .widget .widget_title h2 {
  color: $secondary-color;
  position: relative;
  line-height: 1.2;
  padding-bottom: 13px;
  margin-bottom: 27px;
  border: none;
  @include font-title(24);
  text-transform: none;
}
.sidebar_wrapper .widget .search button {
  @include on-event {
    background: $primary-color;
  }
}
#fmm_socialshare {
  display: none;
}
.blog-post-single {
  p {
    @include font-medium(14);
    line-height: 1.8;
  }
  .post-title {
    text-align: center;
    @include font-title(40);
    line-height: 1.2;
    @include max-width($mobilemenu) {
      @include font-size(24);
    }
  }
  .date {
    @include font-title(18);
    color: $secondary-color;
    text-transform: uppercase;
    line-height: 1;
    display: block;
    text-align: center;
    margin: 5px 0 15px;
    @include max-width($mobilemenu) {
      @include font-size(14);
    }
  }
  .summary {
    @include font-medium(14);
    line-height: 1.8;
    display: block;
    margin-bottom: 0;
  }
  .blog_post_feed {
    padding-top: 0;
  }
}
#module-advanceblog-blog {
  .category-header--blog {
    margin-bottom: 30px;
  }
}
#theme-theme1 {
  .blog_post_feed {
    transition: all 400ms $ease-out;
    box-shadow: $shadow-none;
    transform: translateY(0);
    border-bottom: none;
    @include on-event {
      box-shadow: $shadow-hover-spread;
      transform: translateY(-2px);
    }
    &:focus-within {
      box-shadow: $shadow-hover-spread;
      transform: translateY(-2px);

    }
  }
}
#module-advanceblog-detail {

  .category-header.category-header--blog {
    min-height: unset;
    &:after {
      opacity: 0.7;
    }
    .container-categorycover {
      padding-bottom: 40px;
    }
  }
  .breadcrumb-block--blog {
    width: 100%;
  }
  .blog-post-single {
    margin-top: 40px;
  }

  .post-title.entry-title {
    max-width: 640px;
    margin: 35px auto;
    text-align: center;
  }
  .date {
    display: block;
    @include font-medium(16);
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
  }
  .summary {
    margin-bottom: 40px;
  }
  .blog-detail-thumbnail {
    margin-bottom: 40px;
  }
  .blog_post_feed {
    background-color: transparent;
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: unset;
    }
    p {
      @include font-content(14);
      line-height: 1.8;
      color: $black;
      padding: 0;
    }
    ul li {
      @include font-content(14);
      line-height: 1.8;
      padding: 5px 10px;
      color: black;
      margin: 0 0 0 12px;
    }
  }
  .main_slider {
    background-color: transparent;
    text-align: center;
  }
  .cloud-label-widget-content .label-size a{
    @include on-event{
      background: $primary-color;
      text-decoration: none;
    }
  }
}
#module-advanceblog-category {
  .main_wrapper {
    .main_slider {
      background: transparent;
      padding: 0;
      margin-bottom: 0;
    }
  }
  .sidebar_wrapper {
    padding-top: 93px;
    @include max-width($mobilemenu) {
      padding-top: 0;
    }
  }
  #fmeblog-search {
  }
}
