/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  font-family: $content-font;
}
h1,
.h1 {
  @include font-title(36);
  @include max-width($mediuml) {
    @include font-size(32);
  }
  @include max-width($small) {
    @include font-size(26);
  }
}
h2,
.h2 {
  margin-bottom: 0.5em;
  @include font-title(30);
  @include max-width($mediuml) {
    @include font-size(24);
  }
  @include max-width($small) {
    @include font-size(22);
  }
}
h3,
.h3 {
  margin-bottom: 0.5em;
  @include font-title(24);
  @include max-width($mediuml) {
    @include font-size(22);
  }
  @include max-width($small) {
    @include font-size(20);
  }
}
h4,
.h4 {
  margin-bottom: 0.5em;
  @include font-title(22);
  @include max-width($mediuml) {
    @include font-size(20);
  }
  @include max-width($small) {
    @include font-size(18);
  }
}
h5,
.h5 {
  @include font-title(21);
  @include max-width($mediuml) {
    @include font-size(19);
  }
  @include max-width($small) {
    @include font-size(18);
  }
}
h6,
.h6 {
  @include font-title(18);
  @include max-width($mediuml) {
    @include font-size(18);
  }
  @include max-width($small) {
    @include font-size(18);
  }
}
p,
.p {
  @include font-size(16);
  line-height: 1.8;
  @include max-width($mediuml) {
    @include font-size(16);
  }
  @include max-width($small) {
    @include font-size(16);
  }
}
ul,
ol {
  @include font-size(16);
  line-height: 1.8;
  @include max-width($mediuml) {
    @include font-size(16);
  }
  @include max-width($small) {
    @include font-size(16);
  }
}
.small-uppercase-title{
  @include font-uppercase(14);
  @include max-width($small) {
    @include font-size(10);
  }
}