$green-tat: $green;
$red-tat: $red;
$background-grey-tat: $light-background;
$text-color-tat: $black;
$grey-tat: $dark-grey;
$dark-background-tat: darken($dark-grey,30%);
$border-radius-tat: 0;

.tarteaucitronBeforeVisible {
  &:before {
    content: '';
    background-color: rgba(77, 77, 77, 0.7);
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    // pointer-events: none;
  }
}
div#tarteaucitronAlertBig {
  &:focus {
    outline: 0;
  }
}
.tarteaucitron-modal-open {
  // overflow: hidden;
  // height: 100%;
}
#tarteaucitronContentWrapper {
  display: unset;
}
span.tarteaucitronReadmoreSeparator {
  display: inline !important;
}
.tarteaucitronName {
  .tacCurrentStatus {
    color: $dark-background-tat !important;
    font-size: 12px !important;
    text-transform: capitalize;
  }
  .tarteaucitronReadmoreSeparator {
    color: $dark-background-tat !important;
    font-size: 12px !important;
    text-transform: capitalize;
  }
}
button.tarteaucitron-toggle-group {
  display: block;
}
span.tarteaucitronH3 {
  font-weight: 700 !important;
}
#tarteaucitron {
  #tarteaucitronServices_mandatory {
    .tarteaucitronH3 {
      font-weight: 500 !important;
      font-size: 14px;
      margin-top: 7px;
    }
  }
  .clear {
    clear: both;
  }
  a {
    color: rgb(63, 63, 63);
    font-size: 11px;
    font-weight: 700;
    text-decoration: none;
  }
  strong {
    font-size: 22px;
    font-weight: 500;
  }
  ul {
    padding: 0;
  }
  .tarteaucitronH1 {
    display: block;
  }
  .tarteaucitronH2 {
    display: block;
  }
  .tarteaucitronH3 {
    display: block;
    font-size: 18px;
  }
  .tarteaucitronH4 {
    display: block;
  }
  .tarteaucitronH5 {
    display: block;
  }
  .tarteaucitronH6 {
    display: block;
  }
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
  .tarteaucitronBorder {
    background: $white;
    border: 2px solid $dark-background-tat;
    border-top: 0;
    height: auto;
    overflow: auto;
    border-color: $dark-background-tat !important;
    border-bottom-left-radius: $border-radius-tat;
    border-bottom-right-radius: $border-radius-tat;
  }
  #tarteaucitronClosePanel {
    background: $dark-background-tat;
    color: $white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    padding: 4px 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 70px;
    border-top-left-radius: $border-radius-tat;
    border-top-right-radius: $border-radius-tat;
  }
  #tarteaucitronDisclaimer {
    color: $dark-background-tat;
    font-size: 12px;
    margin: 15px auto 0;
    width: 80%;
  }
  #tarteaucitronServices {
    .tarteaucitronAllow {
      background: $green-tat;
    }
    .tarteaucitronHidden {
      background: rgba(51, 51, 51, 0.07);
      display: none;
      position: relative;
    }
    .tarteaucitronTitle {
      button {
        color: $white;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        margin: 20px 0px 0px;
        padding: 5px 20px;
        text-align: left;
        width: auto;
        background: $dark-background-tat;
      }
      a {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        &:hover {
          text-decoration: none !important;
        }
      }
    }
    .tarteaucitronDetails {
      color: $white;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin: 20px 0px 0px;
      padding: 5px 20px;
      text-align: left;
      width: auto;
      background: $dark-background-tat;
      color: $white;
      display: none;
      font-size: 12px;
      font-weight: 500;
      margin-top: 0;
      max-width: 270px;
      padding: 20px;
      position: absolute;
      z-index: 2147483647;
    }
    .tarteaucitronLine.tarteaucitronMainLine {
      .tarteaucitronName {
        a {
          color: $white;
          font-weight: 500;
          font-size: 22px;
          &:hover {
            text-decoration: none !important;
          }
        }
        margin-left: 15px;
        margin-top: 2px;
        button {
          color: $white;
        }
      }
      background: $dark-background-tat;
      border: 3px solid $dark-background-tat;
      border-left: 9px solid $dark-background-tat;
      border-top: 5px solid $dark-background-tat;
      margin-bottom: 0;
      margin-top: 21px;
      position: relative;
      border-color: $dark-background-tat !important;

      .tarteaucitronAsk {
        margin-top: 0px !important;
      }
    }
    .tarteaucitronLine {
      background: $background-grey-tat;
      border-left: 5px solid transparent;
      margin: 0;
      overflow: hidden;
      padding: 15px 5px;
      .tarteaucitronName {
        display: inline-block;
        float: left;
        margin-left: 10px;
        text-align: left;
        width: 50%;
        a {
          &:hover {
            text-decoration: underline;
          }
        }
        .tarteaucitronListCookies {
          color: $dark-background-tat;
          font-size: 12px;
        }
      }
      .tarteaucitronAsk {
        display: inline-block;
        float: right;
        margin: 7px 15px 0;
        text-align: right;
        .tarteaucitronAllow {
          background: $green-tat;
          border-radius: 4px;
          color: $white;
          cursor: pointer;
          display: inline-block;
          padding: 6px 10px;
          text-align: center;
          text-decoration: none;
          width: auto;
          border: 0;
        }
        .tarteaucitronDeny {
          background: $dark-background-tat;
          border-radius: 4px;
          color: $white;
          cursor: pointer;
          display: inline-block;
          padding: 6px 10px;
          text-align: center;
          text-decoration: none;
          width: auto;
          border: 0;
        }
      }
    }
    .tarteaucitronLine.tarteaucitronIsAllowed {
      border-color: $primary-color;
      .tarteaucitronAllow {
        background-color: $green-tat;
      }
    }
    .tarteaucitronLine.tarteaucitronIsDenied {
      border-color: $red-tat;
      .tarteaucitronDeny {
        background-color: $red-tat;
      }
    }
    #tarteaucitronAllAllowed.tarteaucitronIsSelected {
      background-color: $green-tat;
      opacity: 1;
    }
    #tarteaucitronAllDenied.tarteaucitronIsSelected {
      background-color: $red-tat;
      opacity: 1;
    }
    #tarteaucitronAllDenied2.tarteaucitronIsSelected {
      background-color: $red-tat;
      opacity: 1;
    }
    #tarteaucitronServices_mandatory {
      .tarteaucitronLine {
        button.tarteaucitronAllow {
          background-color: $primary-color;
          opacity: 0.4;
        }
      }
    }
  }
  #tarteaucitronInfo {
    color: $white;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0px 0px;
    padding: 5px 20px;
    text-align: left;
    width: auto;
    background: darken($dark-background-tat,20%);
    color: $white;
    display: none;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
    max-width: 270px;
    padding: 20px;
    position: absolute;
    z-index: 2147483647;
    a {
      color: $white;
      text-decoration: underline;
    }
  }
  #tarteaucitronMainLineOffset {
    .tarteaucitronName {
      width: auto !important;
      margin-left: 0 !important;
      font-size: 14px;
    }
  }
  #tarteaucitronPrivacyUrl {
    background: $primary-color;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
    background: $white;
    color: $dark-background-tat;
    font-size: 13px;
    margin-bottom: 3px;
    margin-left: 7px;
    padding: 5px 10px;
  }
  #tarteaucitronPrivacyUrlDialog {
    background: $primary-color;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
    background: $white;
    color: $dark-background-tat;
    font-size: 13px;
    margin-bottom: 3px;
    margin-left: 7px;
    padding: 5px 10px;
  }
}
.tarteaucitronLine {
  border-left: 0px solid transparent !important;
  .tarteaucitronAllow {
    opacity: 1;
  }
  .tarteaucitronDeny {
    opacity: 1;
  }
}
#tarteaucitronRoot {
  div {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  span {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  applet {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  object {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  iframe {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  h1 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  h2 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  h3 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  h4 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  h5 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    quotes: none;
    &:before {
      content: '';
      content: none;
    }
    &:after {
      content: '';
      content: none;
    }
  }
  pre {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  a {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    &:focus-visible {
      outline: 3px dashed #3d86d8;
    }
  }
  abbr {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  acronym {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  address {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  big {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  cite {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  code {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  del {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  dfn {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  em {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  img {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  ins {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  kbd {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  q {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    quotes: none;
    &:before {
      content: '';
      content: none;
    }
    &:after {
      content: '';
      content: none;
    }
  }
  s {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  samp {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  small {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  strike {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  strong {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  sub {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  sup {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  tt {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  var {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  b {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  u {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  i {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  center {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  dl {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  dt {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  dd {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  ol {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    list-style: none;
  }
  ul {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    list-style: none;
  }
  li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  form {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  label {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  legend {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  table {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    border-collapse: collapse;
    border-spacing: 0;
  }
  caption {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  tbody {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  tfoot {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  thead {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  tr {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  th {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  article {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  aside {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  canvas {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  details {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  embed {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  figure {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  figcaption {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  footer {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  header {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  hgroup {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  menu {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  nav {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  output {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  ruby {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  section {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
    display: block;
  }
  summary {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  time {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  mark {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  audio {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-align: initial;
    text-shadow: initial;
  }
  * {
    transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
    box-sizing: border-box;
    color: $dark-background-tat;
    font-family: inherit;
    font-size: 14px;
    line-height: normal;
    vertical-align: initial;
  }
  button {
    &:focus-visible {
      outline: 2px solid black;
    }
    background: transparent;
    border: 0;
  }
  div#tarteaucitron {
    left: 0;
    right: 0;
    margin: auto;
    button {
      &:focus-visible {
        outline:  3px dashed #3d86d8;
      }
    }
  }
  button#tarteaucitronBack {
    background: $grey-tat;
  }
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  .tarteaucitronH1 {
    font-size: 1.5em;
    text-align: center;
    color: $white;
    margin: 15px 0 28px;
  }
  .tarteaucitronH2 {
    display: inline-block;
    margin: 12px 0 0 10px;
    color: $white;
  }
  #tarteaucitronAlertBig {
    background: $white;
    color: $text-color-tat;
    display: none;
    font-size: 15px !important;
    left: 0;
    position: fixed;
    z-index: 2147483645;
    text-align: center;
    padding: 20px 130px;
    box-sizing: border-box;
    margin: auto;
    width: 100%;
    @media (max-width: 680px) {
      padding: 20px 0;
    }
    
  }
  .tarteaucitronDeny {
    background: transparent;
    color: $grey-tat;
    cursor: pointer;
    display: inline-block;
    font-size: 14px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
  }
  #tarteaucitronAllDenied2 {
    order: 4;
    position: absolute;
    top: 26px;
    right: 5px;
    @media (max-width: 680px) {
      position: static;
    }
    @media (max-width: 400px) {
      margin-top: 10px;
      width: 100%;
      margin-left: 0;
    }
  }
  .tarteaucitronAllow {
    background: $primary-color;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
  }
  .tarteaucitronCross {
    display: none;
    // &::before {
    // 	content: '\2717';
    // 	display: inline-block;
    // 	color: $grey-tat;
    // }
  }
  .tarteaucitronCheck {
    display: none;
    // &::before {
    // 	content: '\2713';
    // 	display: inline-block;
    // 	color: white;
    // }
  }
  .tarteaucitronPlus {
    &::before {
      content: '\271b';
      display: inline-block;
      color: $white;
    }
  }
}
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}
div#tarteaucitronServices {
  margin-top: 21px !important;
  box-shadow: 0 14px 30px #646363bf;
  background: $white;
  border-radius: $border-radius-tat;
}
.catToggleBtn {
  border-top-right-radius: $border-radius-tat;
  border-bottom-right-radius: $border-radius-tat;
}
#tarteaucitronServices {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    outline: 0px solid slategrey;
  }
}
#tarteaucitronAlertBig {
  strong {
    color: $white;
  }
  a {
    color: $white;
  }
  #tarteaucitronPrivacyUrl {
    font: 15px $content-font;
    color: $grey-tat;
    cursor: pointer;
    display: none;
  }
  #tarteaucitronPrivacyUrlDialog {
    font: 15px $content-font;
    color: $white;
    cursor: pointer;
  }
  #tarteaucitronDisclaimerAlert {
    font: 15px $content-font;
    color: $text-color-tat;
    text-align: center;
    @media (max-width: 1300px) {
      width: 100%;
    }
    strong {
      font: 15px $content-font;
      color: $white;
      font-weight: 700;
    }
  }
  #tarteaucitronCloseAlert {
    background: $secondary-color;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 10px 10px;
    text-decoration: none;
    margin-left: 7px;
    font-size: 13px;
    border-radius: $border-radius-tat;
  }
  #tarteaucitronPersonalize {
    background: $primary-color;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
  }
  #tarteaucitronPersonalize2 {
    background: $primary-color;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 10px 10px;
    text-decoration: none;
    margin-left: 7px;
  }
}
#tarteaucitronAlertSmall {
  strong {
    color: $white;
  }
  a {
    color: $white;
  }
  #tarteaucitronCookiesListContainer {
    #tarteaucitronClosePanelCookie {
      background: $dark-background-tat;
      color: $white;
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      padding: 4px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 70px;
    }
    #tarteaucitronCookiesList {
      .tarteaucitronHidden {
        border-color: $dark-background-tat !important;
      }
      .tarteaucitronTitle {
        color: $white;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        margin: 20px 0px 0px;
        padding: 5px 20px;
        text-align: left;
        width: auto;
        background: $dark-background-tat;
        padding: 5px 10px;
        margin: 0;
      }
      .tarteaucitronCookiesListMain {
        border-color: $dark-background-tat !important;
        background: $background-grey-tat;
        padding: 7px 5px 10px;
        word-wrap: break-word;

        a {
          color: $dark-background-tat;
          text-decoration: none;
        }
        .tarteaucitronCookiesListLeft {
          display: inline-block;
          width: 50%;
          a {
            strong {
              color: darkred;
            }
          }
        }
        .tarteaucitronCookiesListRight {
          color: $dark-background-tat;
          display: inline-block;
          font-size: 11px;
          margin-left: 10%;
          vertical-align: top;
          width: 30%;
        }
      }
      border-color: $dark-background-tat !important;
      background: $white;
      border: 2px solid $dark-background-tat;
      color: $dark-background-tat;
      font-size: 11px;
      height: auto;
      overflow: auto;
      text-align: left;
      strong {
        color: $dark-background-tat;
      }
    }
    display: none;
    max-height: 70%;
    max-width: 500px;
    position: fixed;
    right: 0;
    width: 100%;
    #tarteaucitronCookiesTitle {
      background: $dark-background-tat;
      margin-top: 21px;
      padding: 13px 0 9px 13px;
      text-align: left;
      strong {
        color: $white;
        font-size: 16px;
      }
    }
  }
  background: $dark-background-tat;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
  #tarteaucitronManager {
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 11px !important;
    padding: 8px 10px 8px;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
    #tarteaucitronDot {
      background-color: gray;
      border-radius: 5px;
      display: block;
      height: 8px;
      margin-bottom: 1px;
      margin-top: 5px;
      overflow: hidden;
      width: 100%;
      #tarteaucitronDotGreen {
        display: block;
        float: left;
        height: 100%;
        width: 0%;
        background-color: $green-tat;
      }
      #tarteaucitronDotYellow {
        display: block;
        float: left;
        height: 100%;
        width: 0%;
        background-color: #fbda26;
      }
      #tarteaucitronDotRed {
        display: block;
        float: left;
        height: 100%;
        width: 0%;
        background-color: #9c1a1a;
      }
    }
  }
  #tarteaucitronCookiesNumber {
    background: rgba(255, 255, 255, 0.2);
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    padding: 0px 10px;
    vertical-align: top;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}
.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}
#tarteaucitronBack {
  background: $white;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}
#tarteaucitronCookiesList {
  .tarteaucitronH3.tarteaucitronTitle {
    width: 100%;
    box-sizing: border-box;
  }
}
.tac_activate {
  .tarteaucitronAllow {
    background: $dark-background-tat;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    padding: 6px 10px;
    text-align: center;
    text-decoration: none;
    width: auto;
    border: 0;
  }
  background: $dark-background-tat;
  color: $white;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
  .tac_float {
    strong {
      color: $white;
    }
    .tarteaucitronAllow {
      background-color: $primary-color;
      display: inline-block;
    }
  }
}
span#tarteaucitronDisclaimerAlert {
  padding: 10px;
  display: inline-block;
}
.tarteaucitronAlertBigTop {
  top: 0;
}
.tarteaucitronAlertBigBottom {
  bottom: 0;
  padding: 10px 0;
  box-shadow: -5px 0 10px #4e4e4e;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.tarteaucitronCTAButton {
  background: $primary-color;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  line-height: 1.2;
  padding: 5px 10px;
  text-decoration: none;
  margin-left: 7px;
  border-radius: $border-radius-tat;
}
#tarteaucitronPercentage {
  background: #0a0 !important;
  box-shadow: 0 0 2px $white, 0 1px 2px $dark-background-tat;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 15px;
  border-radius: 5px;
  transform: translateY(75%);
  transition: all 400ms ease-in-out;
  @media screen and (max-width: 767px) {
	transform: translateY(5px);
  }
  #tarteaucitronManager {
    border-radius: 7px 7px 2px 2px;
  }
}
.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
  #tarteaucitronManager {
    border-radius: 7px 7px 2px 2px;
  }
}
.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
  #tarteaucitronManager {
    border-radius: 7px 2px 2px 7px;
  }
}
.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
  #tarteaucitronManager {
    border-radius: 2px 7px 7px 2px;
  }
}

#tarteaucitronIcon {
  background: $primary-color;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646;
  transition: all 300ms cubic-bezier(1, 0, 0, 1);
  &:hover,
  &:focus {
    transform: translateY(0);
  }
  #tarteaucitronManager {
    color: transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 11px !important;
    padding: 8px 10px 8px;
    border: none;
	
	@media screen and (max-width: 767px) {
		padding: 3px 10px 0;
	  }
    img {
      width: 50px;
      height: 50px;
	  @media screen and (max-width: 767px) {
		width: 30px;
		height: 27px;
	  }
    }
  }
}
.tarteaucitronAlertSmallTop {
  bottom: 0;
}
.tarteaucitronAlertSmallBottom {
  bottom: 0;
}
.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
ins.ferank-publicite {
  text-decoration: none;
}
ins.adsbygoogle {
  text-decoration: none;
}
div.amazon_product {
  height: 240px;
  width: 120px;
}
.tarteaucitronIsAllowed {
  .tarteaucitronDeny {
    opacity: 0.4 !important;
  }
  .tarteaucitronAllow {
    opacity: 1 !important;
  }
}
.tarteaucitronIsDenied {
  .tarteaucitronAllow {
    opacity: 0.4 !important;
  }
  .tarteaucitronDeny {
    opacity: 1 !important;
  }
}
#tarteaucitronServices_mandatory {
  button.tarteaucitronAllow {
    opacity: 1;
  }
}
div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 100% !important;
  padding: 15px 0 !important;
  margin: -10px auto 40px !important;
  font-size: 1em !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: $dark-background-tat;
}
a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px;
}
.tarteaucitronMainLine {
  .tarteaucitronH2 {
    font-size: 1.2em !important;
    margin-top: 4px !important;
  }
}
span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}
#tarteaucitronCloseCross {
  position: absolute;
  color: $white;
  font-size: 1.8rem;
  cursor: pointer;
  top: 10px;
  right: 26px;
}
.tarteaucitron-spacer-20 {
  height: 20px;
  display: block;
}
.tarteaucitron-display-block {
  display: block;
}
.tarteaucitron-display-none {
  display: none;
}
@media screen and (max-width: 767px) {
  html {
    body {
      #tarteaucitronRoot {
        #tarteaucitron {
          ul#tarteaucitronServices_mandatory {
            .tarteaucitronDeny {
              display: none !important;
            }
          }
          .tarteaucitronBorder {
            button {
              width: 100% !important;
              display: block !important;
              margin-left: 0 !important;
              margin-right: 0 !important;
              box-sizing: border-box !important;
              max-width: 100% !important;
              margin-bottom: 8px !important;
            }
            ul {
              .tarteaucitronLine {
                padding: 16px !important;
              }
            }
            button.tarteaucitron-toggle-group {
              width: 10% !important;
              position: absolute;
              top: 20px;
              right: 20px;
              font-size: 0px;
              padding: 10px 0;
              &:before {
                content: '\0025BE';
                font-weight: 700;
                font-size: 14px;
              }
            }
            .tarteaucitronIsExpanded {
              button.tarteaucitron-toggle-group {
                &:before {
                  content: '\0025B4';
                }
              }
            }
          }
          .tarteaucitronAsk {
            width: 100% !important;
            display: block !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            box-sizing: border-box !important;
            max-width: 100% !important;
            margin-bottom: 8px !important;
          }
          .tarteaucitronName {
            width: 100% !important;
            display: block !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            box-sizing: border-box !important;
            max-width: 100% !important;
            margin-bottom: 8px !important;
          }
          div#tarteaucitronMainLineOffset {
            .tarteaucitronName {
              display: none !important;
            }
          }
        }
      }
    }
  }
  #tarteaucitronServices_mandatory {
    li.tarteaucitronLine {
      .tarteaucitronName {
        span {
          width: 100% !important;
          display: inline-block;
        }
      }
    }
  }
  li.tarteaucitronLine {
    .tarteaucitronName {
      span {
        width: 80% !important;
        display: inline-block;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  html {
    body {
      #tarteaucitronRoot {
        #tarteaucitron {
          .tarteaucitronBorder {
            button.tarteaucitron-toggle-group {
              &:after {
                content: '\0025BE';
                font-weight: 700;
                font-size: 14px;
                margin-left: 15px;
              }
            }
            .tarteaucitronIsExpanded {
              button.tarteaucitron-toggle-group {
                &:after {
                  content: '\0025B4';
                  margin-left: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  #tarteaucitron {
    .tarteaucitronLine {
      .tarteaucitronName {
        width: 90% !important;
      }
      .tarteaucitronAsk {
        float: left !important;
        margin: 10px 15px 5px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall {
    #tarteaucitronCookiesListContainer {
      background: $white;
      border: 0 !important;
      bottom: 0 !important;
      height: 100% !important;
      left: 0 !important;
      margin: 0 !important;
      max-height: 100% !important;
      max-width: 100% !important;
      top: 0 !important;
      width: 100% !important;
      #tarteaucitronCookiesList {
        border: 0 !important;
      }
    }
  }
  #tarteaucitron {
    background: $white;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
    .tarteaucitronBorder {
      border: 0 !important;
    }
    #tarteaucitronServices {
      .tarteaucitronTitle {
        text-align: left !important;
      }
      .tarteaucitronLine {
        .tarteaucitronAsk {
          text-align: center !important;
          button {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .tarteaucitronName {
    .tarteaucitronH2 {
      max-width: 80%;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}
