// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.container--product-finder {
  @include flex-row-nowrap;
  justify-content: center;
  align-items: stretch;
  margin-top: -50px;
  position: relative;
  z-index: 3;
  margin-bottom: 75px;
  @include max-width($small) {
    margin-top: 0;
    flex-flow: column;
    padding: 0;
    margin-bottom: 60px;
  }
}
.pf-black-container {
  @include flex-column(space-between, flex-start);
  flex-basis: 56%;
  @include max-width($mediuml) {
    flex-basis: 45%;
  }
  @include max-width($small) {
    order: 2;
    width: 100%;
  }
  .pf-black-container-inner {
    background: $secondary-color;
    color: $white;
    padding: 35px 45px 90px;

    @include max-width($mediuml) {
      padding: 35px 25px 40px;
      flex-basis: 45%;
    }
    @include max-width($small) {
      padding: 25px 30px 40px 10px;
    }
  }
  .link-with-icon {
    margin: auto 0;
    padding: 15px 0;
    @include max-width($small) {
      display: none;
    }
  }
}
.pf-red-container {
  background: $primary-color;
  color: $white;
  padding: 35px 33px 110px;
  flex-basis: 44%;
  position: relative;
  @include max-width($mediuml) {
    padding: 35px 25px 110px;
    flex-basis: 55%;
  }
  @include max-width($small) {
    order: 1;
    width: 100%;
    padding: 25px 30px 25px 10px;
  }
}
.pf-red-container-inner {
  position: relative;
  z-index: 2;
  .search-widget {
    margin-top: 20px;
  }
}
.pf-bg-image {
  @include abs-position(auto, 22px, 15px, auto);
  z-index: 1;
  max-width: 80%;
  width: 350px;
  @include max-width($small) {
    right: -10px;
    bottom: 0;
    max-height: 100%;
    width: auto;
  }
}

.home-cat-header {
  @include flex-row-nowrap(space-between, center);
  margin-bottom: 25px;
  h2 {
    margin-bottom: 0;
    @include max-width($small) {
      @include font-size(20);
    }
  }
}
.home-cat-link-gammes {
  @include font-bold(16);
  text-decoration: underline;
  @include max-width($small) {
    @include font-size(14);
  }
}
.home-cats-container {
  margin-bottom: 80px;
  @include flex-row-wrap(space-between, flex-start);
  @include max-width($small) {
    justify-content: center;
    margin-bottom: 40px;
  }
}
.home-cat-single-big {
  width: 32.5%;
  background: $white;
  margin-bottom: 21px;
  transition: all 400ms $ease-out;
  box-shadow: $shadow-none;
  transform: translateY(0);
  @include max-width($mediuml) {
    margin-bottom: 10px;
  }
  @include max-width($small) {
    width: 100%;
  }

  @include on-event {
    box-shadow: $shadow-hover-spread;
    transform: translateY(-2px);
  }
  .home-cat-thumbnail-container {
    position: relative;
    aspect-ratio: 86 / 67; //pas encore d'image
    background-color: $white;
    overflow: hidden;
    span {
      @include abs-position(24px, auto, auto, 21px);
      padding: 6px 12px;
      background-color: white;
      color: $black;
      @include font-bold(16);
      max-width: calc(100% - 48px);
      display: inline-block;
    }
  }
  .home-cat-thumb {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .home-cat-button-container {
    @include flex-row-wrap(space-between, center);
    padding: 15px 20px;
    span {
      @include font-bold(14);
      color: $primary-color;
    }
  }
  svg {
    path {
      transition: transform 600ms $ease-in-out-faster;
    }
    .circle {
      transform: scale(1) rotate(0);
      transform-origin: center;
    }
    .arrow {
      transform: translateX(0);
    }
  }
  @include on-event {
    svg {
      .circle {
        transform: scale(0.6) rotate(90deg);
      }
      .arrow {
        transform: translateX(12px);
      }
    }
  }
  &:focus-visible {
    outline: 3px solid $black;
  }
}
.home-cat-single-small {
  width: 24%;
  background: $white;
  color: $black;
  margin-bottom: 21px;
  box-shadow: $shadow-none;
  transform: translateY(0);
  @include flex-row-nowrap(space-between, center);
  padding: 10px;
  @include max-width($large) {
    width: 49%;
    margin-bottom: 10px;
  }
  @include max-width($small) {
    width: 100%;
  }
  @include on-event {
    box-shadow: $shadow-hover-spread;
    transform: translateY(-2px);
  }
  span {
    @include font-bold(16);
    color: $secondary-color;
    margin: 0 auto 0 10px;
    flex-shrink: 1;
  }
  .home-cat-thumb {
    aspect-ratio: 1 / 1; //pas encore d'image
    background-color: $grey;
    width: 64px;
    flex-shrink: 0;
  }
  svg {
    flex-shrink: 0;
    path {
      transition: transform 600ms $ease-in-out-faster;
    }
    .circle {
      transform: scale(1) rotate(0);
      transform-origin: center;
    }
    .arrow {
      transform: translateX(0);
    }
  }
  @include on-event {
    color: $black;
    svg {
      .circle {
        transform: scale(0.6) rotate(90deg);
      }
      .arrow {
        transform: translateX(12px);
      }
    }
  }
  &:focus-visible {
    outline: 3px solid $black;
  }
}

.container--catalogue-search {
  @include flex-row-nowrap;
  justify-content: center;
  align-items: stretch;
  position: relative;
  z-index: 3;
  margin-bottom: 0;
  @include max-width($small) {
    flex-flow: column;
    padding: 0;
  }
  &.container--catalogue-search--footer {
    margin: 100px 0 160px;
    @include max-width($small) {
      margin: 80px 0;
    }
  }
}

.cs-red-container {
  background: $primary-color;
  color: $white;
  padding: 35px 33px;
  flex-basis: 50%;
  position: relative;
  overflow: hidden;
  transform: translateY(60px);
  @include max-width($mediuml) {
  }
  @include max-width($small) {
    transform: translateY(0);
    width: 100%;
    padding: 25px 30px 25px;
  }
  @include on-event {
    // background: $secondary-color;
    color: $light-grey;
    .cs-bg-image {
      transform: translateX(150%);
    }
  }
  &:focus-visible {
    outline: 3px solid $black;
  }
  .cs-red-container-inner {
    position: relative;
    z-index: 2;
    max-width: 460px;
  }
  .cs-bg-image {
    @include abs-position(auto, 33px, 25px, auto);
    z-index: 1;
    max-width: 70%;
    width: 307px;
    pointer-events: none;
    transform: translateX(0);
    transition: transform 1200ms $ease-in-out-fast;
    @include max-width($small) {
      right: 10px;
      bottom: 10px;
    }
  }
}

.cs-white-container {
  @include flex-row-nowrap(flex-end, flex-start);
  position: relative;
  overflow: hidden;
  position: relative;
  flex-basis: 50%;
  background: $white;
  color: $secondary-color;
  padding: 35px 33px;

  @include max-width($mediuml) {
  }
  @include max-width($small) {
    width: 100%;
    padding: 25px 30px;
  }
  @include on-event {
    // background: $light-grey;
    color: $primary-color;
    .cs-bg-image {
      transform: translateX(-150%);
    }
  }
  &:focus-visible {
    outline: 3px solid $black;
  }
  .cs-white-container-inner {
    position: relative;
    z-index: 2;
    max-width: 400px;
    text-align: right;
  }

  .cs-bg-image {
    @include abs-position(auto, auto, 25px, 33px);
    z-index: 1;
    max-width: 50%;
    width: 192px;
    pointer-events: none;
    transform: translateX(0);
    transition: transform 1200ms $ease-in-out-fast;
    opacity: 0.2;
    @include max-width($small) {
      left: 10px;
      bottom: 10px;
    }
  }
}
.container--savoir-faire {
  @include flex-row-nowrap(center, stretch);
  margin-bottom: 60px;
  @include max-width($small) {
    @include flex-column();

    margin-bottom: 40px;
  }
}
.sf-img-container {
  position: relative;
  flex-basis: 50%;
  @include max-width($small) {
    flex-basis: 100%;
    width: 100%;
  }
}
.sf-image {
  object-fit: cover;
  object-position: center;
  @include abs-position(0, 0, 0, 0);
  z-index: 1;
  width: 100%;
  height: 100%;
  @include max-width($small) {
    position: relative;
    height: auto;
    max-width: 100%;
    object-fit: unset;
    object-position: unset;
  }
}
.sf-headline {
  @include font-uppercase();
  position: relative;
  z-index: 2;
  margin-top: 80px;
  padding: 0 1rem;
  @include max-width($small) {
    margin-top: 60px;
    position: relative;
    height: auto;
    object-fit: unset;
    object-position: unset;
  }
}
.sf-headline-red {
  color: $primary-color;
  // opacity: 0.7;
  text-align: right;
  text-shadow: 0px 0px 1px #0006;

  @include max-width($small) {
    @include abs-position(auto, 0, -65px, 0);
    text-align: center;
    padding-right: 20%;
  }
}
.sf-headline-white {
  color: $white;

  @include max-width($small) {
    text-align: center;
    padding-left: 20%;
  }
}

.sf-black-container {
  flex-basis: 50%;
  background: $secondary-color;
  color: $white;
  @include max-width($small) {
    width: 100%;
    flex-basis: 100%;
  }
}
.sf-container-inner {
  padding: 30px 45px;
  width: 100%;
  max-width: 585px;
  @include max-width($small) {
    padding: 30px 10px;
  }
  p {
    @include font-size(13);
  }
  hr {
    border-bottom: 1px solid $white;
    margin: 25px 0;
  }
  .button--white {
    margin-top: 25px;
    color: $primary-color;
  }
}
.container--home-blog {
  margin-bottom: 60px;
  @include max-width($small) {
    margin-bottom: 40px;
  }
}
.home-blog-posts {
  @include flex-row-nowrap(space-between, stretch);
  @include max-width($small) {
    flex-flow: column nowrap;
  }
}
.home-blog-post {
  width: 32.5%;

  @include max-width($small) {
    width: 100%;
  }
}
.home-post-link {
  @include flex-column(flex-start, flex-start);
  color: $black;
  background-color: $white;
  transition: all 400ms $ease-in-out;
  height: 100%;
  box-shadow: $shadow-none;
  transform: translateY(0);
  @include max-width($small) {
    margin-bottom: 13px;
  }
  @include on-event {
    color: $red;
    text-decoration: none;
    box-shadow: $shadow-hover-spread;
    transform: translateY(-2px);
  }

  border-bottom: none;
}
.home-post-date {
  color: $dark-grey;
  @include font-content(14);
  text-transform: uppercase;
  padding: 30px 23px;
  margin-bottom: 0;
  @include max-width($small) {
    @include font-size(10);
  }
}
.home-post-title {
  padding: 0 23px;
  @include font-bold(20);
  margin: 0 0 37px;
  @include max-width($small) {
    @include font-size(16);
  }
}
.home-blog-separator {
  padding: 0;
  width: 80%;
  border-bottom: $primary-color 1px solid;
  margin: auto 0 0;
}
.home-blog-button {
  width: 100%;
  padding: 23px 26px;
  @include font-size(14);
  color: $primary-color;
  justify-content: space-between;
  @include on-event {
    background-color: $white;
    color: $primary-color;
  }
}
