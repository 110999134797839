// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// FONTS :
// Name every font family here, import are in ../base/_fonts.scss
// -----------------------------------------------------------------------------

/// Primary font family
/// Don't forget to change it in ../base/_typography.scss
/// Don't forget to copy it in ../classic_rocket/abstracts/variables_bootstrap.scss > font-family
$title-font: 'Prompt', sans-serif;
$content-font: 'Prompt', sans-serif;

//Font weights
$regular: 400;
$medium: 600;
$semi-bold: 600;
$bold: 700;

// -----------------------------------------------------------------------------
// COLORS :
// Define every colors here. Depending on the projet, can be "primary", "secondary", "links"... or color names directly.
// -----------------------------------------------------------------------------

// Project colors
$white: white;
$black: black;
$red: #CC0C11;
$orange: #DB6806;
$green: #349C3D;
$grey: #DDDDDD;
$light-grey:#EDEDED;
$dark-grey: #808080;
$darker-grey:rgb(90, 90, 90);
$light-background: #F5F5F5;


/// Main brand color
$primary-color: $red;
$secondary-color: $black;

//Text colors - be careful before deleting these, they are used in base/_typography.scss
$title-color: $black; //Don't remove this
$text-color: $black; //Don't remove this
$secondary-text-color: $dark-grey; //Don't remove this
$links-color: $primary-color; //Don't remove this
$links-hover-color: lighten($primary-color, 10%); //Don't remove this

// -----------------------------------------------------------------------------
// AUTRES
// -----------------------------------------------------------------------------
$shadow-none: 0 0 0 #00000000;
$shadow: 0 3px 8px #00000029;
$shadow-hover: 0 1px 4px #00000062;
$shadow-hover-spread:  0 10px 25px rgba(83, 83, 83, 0.15);

//Container max width
// This is used in ../base/_helpers.scss for ".container" class
$max-width: 1210px;

/// Breakpoints map for responsive. 1em = 10 px with

$xxsmall: 360px;
$xsmall: 450px;
$small: 680px;
$mobilemenu: 939px;
$mediuml: 980px;
$large: 1300px;
$xlarge: 1920px;

//Animation Easing
$ease-in-out: cubic-bezier(0.65, 0.05, 0.36, 1);
$ease-in-out-fast: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-faster: cubic-bezier(0.86, 0, 0.07, 1);
$ease-out: cubic-bezier(0.22, 0.61, 0.08, 1.01);
$bounce-in-out: cubic-bezier(0.68, -0.55, 0.27, 1.55);
$bounce-out: cubic-bezier(0.68, -0.55, 0.27, 1.55);
