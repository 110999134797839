// Hide each slider before Slick is initialized with this css
// .sliderclass { //Slider container class given to slick
//     cursor: grab;
// background-color: $light-background;
// width: 100%;
// overflow: hidden;
// position: relative;
//     &:not(.slick-initialized){ //Only when not initialized
//     visibility: hidden;
//     height: calc(100vh - 200px); //Optionnal : Give an height so it doesn't jump
// }

// Permet d'ajouter une marge entre les images de slide
// .slick-slide {
//     margin: 0 10px;
//   }
//   .slick-list {
//     margin: 0 -10px;
//   }
// }

// .slick-arrow {
//     border: none;
//     background: none;
//     cursor: pointer;
//     padding: 10px 5px;
//      z-index: 1;
//     @include abs-position(-60px, 0, auto, auto);
//     &.slick-prev {
//       transform: translateX(-50px);
//     }
//     svg path {
//       transition: fill 400ms $ease-in-out-fast;
//       fill: $primary-color;
//     }
//     @include on-event() {
//       svg path {
//         fill: $secondary-color;
//       }
//     }
//      &.slick-disabled {
//       opacity: 0.5;
//      }
// &:focus-visible {
//     outline: 3px solid black;
//   }
//   }

.home-slider-container {
  //Slider container class given to slick
  cursor: grab;
  background-color: $light-background;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 540px;
  padding: 0;
  @include max-width($small) {
    height: 300px;
  }

  &:not(.slick-initialized) {
    //Only when not initialized
    visibility: hidden;
    //Optionnal : Give an height so it doesn't jump
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-slide {
    height: 100%;
    > div {
      height: 100%;
    }
  }
  .home-slide {
    height: 100%;
    position: relative;
    color: white;
    text-decoration: none;
    @include on-event {
      text-decoration: none;
      color: white;
      .button--witharrow {
        background: $white;
        color: $secondary-color;
        svg {
          path {
            fill: $secondary-color;
          }
        }
      }
    }
    img {
      @include abs-position(0, 0, 0, 0);
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
    .home-slide-text-container {
      @extend .container;
      @include abs-position(50%, auto, auto, 50%);
      transform: translate(-50%, -50%);
      z-index: 2;
      @include max-width($small) {
        left: 0;
        transform: translate(0);
        bottom: 85px;
        top: auto;
      }
    }
    .home-slide-overlay {
      @include abs-position(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0.4);
      pointer-events: none;
      z-index: 1;
    }
  }
  .home-slide-title {
    max-width: 480px;
    width: 80%;
    display: block;
    @include max-width($small) {
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.459);
    }
  }
  .home-slide-description {
    width: 90%;
    @include max-width($small){
      text-shadow: 0px 0px 5px black;
      p{
        @include font-content(12);
        line-height: 1.4;
      }
    }
  }
  .slick-dots{
      @include abs-position(50%,50%, auto, auto);
      transform: translate(585px,-50%);
      @include max-width($large){
          right: 30px;
          transform: translate(0,-50%);
      }
      @include max-width($small){
        top: auto;
        bottom: 20px;
        left: 10px;
        right: auto;
        transform: translate(0);
        @include flex-row-nowrap(center, center);
        gap: 15px;
    }
    button{
        border: none;
        background: none;
        color: $white;
        @include font-bold(24);
        position: relative;
        width: 56px;
        padding: 3px 0 5px;
        transition: 200ms all $ease-in-out;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.438);
        @include max-width($small){
            @include font-size(16);
            width: 20px;
        }
        &:after{
            content: "";
            background: $white;
            height: 2px;
            width: 100%;
            @include abs-position(auto,0,0,0);
            transform: translateY(0);
            transition: 300ms transform $ease-in-out-fast;
        }
        &:focus-visible{
            outline: 2px solid $primary-color;
        }
        @include on-event{
            color: $primary-color;
            &:after{
                background: $primary-color;
                transform: translateY(2px);
            }
        }
    }
    .slick-active{
        button{
            color: $primary-color;
            &:after{
                background: $primary-color;
            }
        }
    }
  }
}
