//Opart devis
//Create devis :
#opartDevisForm {
  .table#cart_summary {
    font-size: 14px;
    @include max-width($mediuml) {
      font-size: 11px;
    }
    thead {
      th {
        padding: 10px;
      }
    }
    tr {
    }
    td {
      padding: 10px;
    }
  }
  .card {
    margin-top: 40px;
    margin-bottom: 40px;
    box-shadow: $shadow;
    border: none;
    .h6 {
      margin: 0;
    }
    .card-header {
      background-color: white;
      color: black;
    }
  }
}
//List devis
.opart-table-wrapper{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}
#order-list{
  font-size: 14px;
    @include max-width($mediuml) {
      font-size: 11px;
    }
  .fitwidth {
    width: auto;
  }
}