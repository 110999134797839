
.bloc-product-finder-container{
    margin-top: 20px;
    .de_bloc_content{
        .button{
            margin-bottom: 10px;
        }
    }
    
}
#js-modal-content{
    
    .h3{
        margin-bottom: 25px;
    }
    
}
#products_finder_collapse{
    position: relative;
    z-index: 2;
    margin-bottom: 50px;

    .button--white{
        margin-bottom: 14px;
        &[aria-expanded="true"]{
            background: $primary-color;
            border: 2px dashed white;
            color: white;
            svg{
                transform: rotate(90deg);
                path{
                    fill: white;
                }
            }
        }
    }
    .search-products{
        display: block;
        margin: 14px auto 28px;
        @include on-event{
            background: rgb(39, 39, 39);
        }
    }
    select{
        margin-bottom: 14px;
        background-color: $primary-color;
        border: 2px solid $white;
        color: $white;
        font-weight: 700;
        @include on-event{
            border: 2px solid $black;
            background: white;
            color: $black;
        }
    }
}
.pf-modal-bg-image {
    @include abs-position(auto, 0, 0, auto);
    z-index: 1;
    max-width: 50%;
    width: 350px;
    
  }