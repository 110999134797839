// -----------------------------------------------------------------------------
// Base styles taken from Refuge
// -----------------------------------------------------------------------------

.page-customer-account {
  #primary {
    background: $light-background;
  }
}
.page-header--authentication {
  h1 {
    text-align: center;
    margin-bottom: 70px;
  }
}
section.login-form {
  background: white;
  box-shadow: 0px 0px 5px #0000001a;
  border: 1px solid #f6f6f6;
  padding: 22px 0 0;
  position: relative;
}
.login-title {
  @include font-medium(13);
  text-align: center;
  margin-bottom: 20px;
  display: block;
}
#login-form {
  padding: 0 40px;
}

#customer-form {
  .form-fields {
    display: flex;
    flex-flow: column nowrap;
  }
  .form-group {
    &.form-group-typepro {
      background: $light-background;
      padding: 30px 30px 0;
      order: 41 !important;
      margin: 0;
    }

    &.form-group-siret {
      background: $light-background;
      padding: 15px 30px;
      order: 42 !important;
      margin: 0;
    }
    &.form-group-company {
      background: $light-background;
      padding: 0 30px 30px;
      order: 43 !important;
      margin: 0;
    }
  }
  .separator-form-pro {
    order: 44;
    margin: -2px 0 30px;
  }
}
.intro-title-compte-pro {
  margin-bottom: 15px;
}
.intro-compte-pro {
  @include font-size(14);
  margin-bottom: 15px;
}
.form-group {
  margin-bottom: 20px;
  label,
  .label {
    display: block;
    @include font-bold(14);
    text-transform: uppercase;
    margin-top: 5px;
    color: $black;
    &.custom-control-label {
      @include font-content(16);
      text-transform: none;
      font-weight: 400;
    }
  }
  input[type='radio'],
  input[type='checkbox'] {
    width: 22px;
    height: 22px;
  }
  .form-control {
    width: 100%;
    max-width: 750px;
    border: 2px #cccccc solid;
    padding: 10px 20px;
    transition: all 300ms $ease-out;
    color: $black;
    border-radius: 0;
    @include on-event {
      border: 2px $primary-color solid;
      outline: none;
    }
  }
  .custom-select {
    width: 100%;
    max-width: 750px;
    border: 2px #cccccc solid;
    padding: 10px 20px;
    transition: all 300ms $ease-out;
    color: $black;
    border-radius: 0;
    @include on-event {
      border: 2px $primary-color solid;
      outline: none;
    }
  }
  .form-text {
    display: block;
    @include font-size(12);
    padding-top: 7px;
  }
  .text-muted {
    color: #97060a !important;
  }
  .invalid-feedback {
    color: $primary-color;
    margin-top: 4px;
  }
  .custom-control {
    label {
      display: inline;
      padding-left: 5px;
    }
    .label {
      display: block;
    }
  }
  .custom-radio {
    input:nth-of-type(n + 2) {
      margin-left: 40px;
    }
  }
  .custom-control-inline {
    display: inline-block;
    margin: 5px 10px 5px 0;
  }
}
.forgot-password {
  .forgot-password-link {
    @include font-content(12);
    margin-bottom: 20px;
    text-decoration: underline;
    text-align: left;
    display: block;
    width: 100%;
  }
}
.button--login {
  padding: 16px 40px;
  margin: 0 auto 30px;
}
.button--register {
  padding: 16px 40px;
  margin: 20px auto;
}
.no-account {
  padding-top: 55px;
  padding-bottom: 30px;
  background: #f6f6f6;
  width: 100%;
  position: relative;
  text-align: center;
  a {
    @include font-size(12);
    text-decoration: underline;
    text-align: center;
    display: block;
    width: 100%;
  }
}

section.register-form {
  background: white;
  box-shadow: 0px 0px 5px #0000001a;
  border: 1px solid #f6f6f6;
  padding: 22px 40px;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
}
.account-links {
  margin-bottom: 20px;
  .button {
    margin-bottom: 20px;
    transform: scale(0.9);
    border-radius: 0;
    border: none;
    padding: 2rem 3rem;
    @include font-size(16);
    text-align: center;

    @include max-width($mediuml) {
      @include font-size(14);
    }
    @include max-width($small) {
      @include font-size(14);
    }
    .link-item {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      i {
        margin-bottom: 5px;
        @include font-size(30);
        @include max-width($mediuml) {
          @include font-size(24);
        }
        @include max-width($small) {
          @include font-size(24);
        }
      }
    }
  }
}
.account-link {
  @include font-size(16);
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  .material-icons {
    margin-right: 5px;
  }
}

.page-content--module-ps_emailalerts-account {
  ul li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #f6f6f6;
    margin-bottom: 20px;
    padding: 15px;
    a {
      @include font-title;
      @include font-size(18);
      color: $black;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      @include max-width($small) {
        flex-flow: column wrap;
        align-items: flex-start;
        justify-content: flex-start;
      }
      img {
        margin: 0 15px 0 0;
        @include max-width($small) {
          margin: 0 0 5px 0;
        }
      }
      span {
        @include font-title(16);
        margin: 0 20px;
        text-transform: none;
        @include max-width($small) {
          margin: 5px 0;
        }
      }
      &.js-remove-email-alert {
        color: white;
        @include on-event {
          color: $primary-color;
        }
      }
    }
  }
}

.ps-alert-success {
  .item i {
    text-align: center;
    display: block;
    svg {
      max-width: 80px;
      margin: 0 auto 25px;
      path {
        fill: $primary-color;
      }
    }
  }
}
.page-footer--my-account {
  margin-top: 25px;
  margin-bottom: 50px;
}
.page-footer--customer {
  margin-top: 25px;
  margin-bottom: 50px;
  .button-line.button-line--small {
    margin-right: 40px;
  }
}
.form-footer--connexion {
  text-align: center;
}
.address-body {
  @include font-size(14);
}
.address-header{
  .favorite{
    cursor: pointer;
    font-size: 2.5rem;

    &::before{
      content: "\002606";
    }
  }
  .is-favorite{
    &::before{
      content: "\002605";
    }
  }
}
.addresses-footer {
  margin: 25px 0 50px;
}
.page-history {
  h6 {
    display: block;
    margin-bottom: 40px;
  }
  .table {
    @include font-size(14);
    font-weight: $bold;
  }
  .badge {
    @include font-medium(12);
    // background-color: $primary-color !important;
    color: white;
    border-radius: 20px;
    padding: 1em;
    &.dark {
      color: black;
    }
  }
  td.text-sm-center .material-icons {
    @include font-size(20);
  }
  .visible--desktop {
    @include max-width($mobilemenu) {
      display: none;
    }
    .btn {
      @include font-size(13);
    }
  }
  .visible--mobile {
    display: none;
    @include max-width($mobilemenu) {
      display: block;
    }
    .order {
      background-color: white;
      border: 1px solid grey;
      margin-bottom: 20px;
      padding: 20px;
      h3,
      .h3 {
        @include font-size(18);
      }
      .date,
      .total {
        @include font-size(14);
        @include font-title;
        line-height: 2;
      }
      .status {
        margin-top: 20px;
      }
      .btn {
        margin-top: 10px;
      }
    }
  }
  .order-actions {
    .btn {
      padding: 9px;
    }
  }
}
.page-order-detail {
  @include font-size(14);
  h3 {
    display: block;
    margin: 30px 0 30px;
  }
  .visible--desktop {
    @include max-width($mobilemenu) {
      display: none;
    }
  }
  .visible--mobile {
    display: none;
    @include max-width($mobilemenu) {
      display: block;
    }
  }
  .badge {
    @include font-medium(12);
    color: white;
    border-radius: 20px;
    padding: 1em;
    &.dark {
      color: black;
    }
  }
}
