input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search'],
input[type='number'],
input[type='tel'],
input[type='range'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='color'],
textarea {
    color: $black;
    border: 2px solid #eeeeee;
    background: white;
    border-radius: 0;
    padding: 11px 17px;
    width: 100%;
    transition: border 400ms $ease-out;
    @include font-bold(16);
    @include on-event {
        border: 2px solid $primary-color;
        outline: none;
    }
    &:focus {
        color: $text-color;
    }
}
label {
    @include font-bold(16);
    margin-top: 15px;
    margin-bottom: 10px;
    &.required::after {
        content: '*';
        color: $red;
    }
}

.form-group:has(> select) {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        bottom: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: black transparent transparent transparent;
    }

    select {
        -webkit-appearance: none;
    }
}
select {
    color: $black;
    border: 2px solid #eeeeee;
    background: white;
    border-radius: 0;
    padding: 11px 17px;
    width: 100%;
    transition: border 400ms $ease-out;
    @include font-content(16);
    @include on-event {
        border: 2px solid $primary-color;
        outline: none;
    }
    &:focus {
        color: $text-color;
    }
}

textarea {
    width: 100%;
}

input[type='file'] {
    border: 2px solid #eeeeee;
    background: $white;
    padding: 20px;
    @include font-content(16);
    @include on-event {
        border: 2px solid $primary-color;
    }
    @include max-width($small) {
        @include font-size(12);
        padding: 5px;
    }
}
.input-group--password {
    @include flex-row-nowrap(flex-start, stretch);
}
