// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

//Absolute positioning an element
@mixin abs-position($top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
//Font-size from px to rem
@mixin font-size($sizeValue: 16) {
  font-size: ($sizeValue / 10) + rem;
}

@mixin font-content($sizeValue: false) {
  @if $sizeValue {
    @include font-size($sizeValue);
  }
  font-family: $content-font;
  font-weight: $regular;
}

@mixin font-medium($sizeValue: false) {
  @if $sizeValue {
    @include font-size($sizeValue);
  }
  font-family: $content-font;
  font-weight: $semi-bold;
}

@mixin font-semibold($sizeValue: false) {
  @if $sizeValue {
    @include font-size($sizeValue);
  }
  font-family: $content-font;
  font-weight: $semi-bold;
}

@mixin font-bold($sizeValue: false) {
  @if $sizeValue {
    @include font-size($sizeValue);
  }
  font-family: $content-font;
  font-weight: $bold;
}

@mixin font-title($sizeValue: false) {
  @if $sizeValue {
    @include font-size($sizeValue);
  }
  font-family: $title-font;
  font-weight: $bold;
}

@mixin font-uppercase($sizeValue: false) {
  @if $sizeValue {
    @include font-size($sizeValue);
  }
  font-family: $title-font;
  text-transform: uppercase;
  font-weight: $bold;
}

/// Event wrapper, replace &:hover,&:focus,&:active.
// 	Use : @include on-event { }
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

//Add cover background to an element
@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

//Create an arrow element, choose direction, size and color. Perfect for ::after and ::before elements
@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

//Media query max-width (desktop first dev)
@mixin max-width($size) {
  @media (max-width: $size) {
    @content;
  }
}

//Media query min-width (mobile first dev)
@mixin min-width($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin descending-z-index($count: 20) {
  // @include on parent class

  .product-miniature {
    $target: 0;
    $index: $count;
    @while $index > 0 {
      &:nth-child(#{$target}) {
        z-index: #{$index};
      }
      $target: $target + 1;
      $index: $index - 1;
    }
  }
}

//Mixins flex
@mixin flex-row-nowrap($justifycontent: center, $alignitems: center) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: $justifycontent;
  align-items: $alignitems;
}
@mixin flex-row-wrap($justifycontent: center, $alignitems: center) {
  display: flex;
  flex-flow: row wrap;
  justify-content: $justifycontent;
  align-items: $alignitems;
}
@mixin flex-column($justifycontent: flex-start, $alignitems: center) {
  display: flex;
  flex-flow: column nowrap;
  justify-content: $justifycontent;
  align-items: $alignitems;
}
