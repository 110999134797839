// -----------------------------------------------------------------------------
// This file contains bootstrap overrides.
// -----------------------------------------------------------------------------

//Override des styles bootstrap un peu partout dans les parties "Customer" du site
.btn {
  color: white;
  padding: 15px 20px;
  background: $primary-color;
  @include font-size(16);
  @include font-title;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 400ms $ease-out;
  @include on-event() {
    color: $primary-color;
    background: white;
  }
  &.btn-primary {
    color: #fff;
    background: $primary-color;
    border: none;
    box-shadow: none;
  }
}

.alert-info {
  color: white;
  background-color: $primary-color;
  border: none;
  font-weight: $medium;
}
