// -----------------------------------------------------------------------------
// Base styles taken from Refuge
// -----------------------------------------------------------------------------

.page-content--cms {
    margin: 40px auto 0;
    p {
        margin: 30px 0;
        color: $text-color;
        + h2,
        + h3,
        + h4 {
          margin-top: 100px;
        }
    }
    h2 {
        margin: 40px 0 40px;
    }
    h3:not(.timeline__content-edge__title) {
        margin: 20px 0 30px;
    }
    h4 {
        margin: 10px 0 20px;
    }
    ul,
    ol {
        color: $text-color;
        @include font-size(16);
    }
    ul:not(.timeline__content-steps) {
        padding-left: 6rem;
        @include max-width($mobilemenu) {
            padding-left: 2rem;
        }
        li {
            list-style: disc;
            padding-left: 20px;
            margin-left: 15px;
            &::marker {
                color: $primary-color;
            }
        }
        ol {
            list-style: decimal;
            padding-left: 20px;
            margin-left: 15px;
            &::marker {
                color: $primary-color;
            }
        }
    }
    img,
    video {
        margin: 25px auto;
        max-width: 100%;
    }
}

.page-cms {
    .breadcrumb-block {
        margin-bottom: 60px;
        @include max-width($small) {
            margin-bottom: 20px;
        }
    }
}

// Recettes
.breadcrumb-block--recettes {
    margin-bottom: 20px !important;
}
.page-header--recettes {
    .single-product-top-container {
        align-items: center;
    }

    .column-infos {
        margin-top: 0;
    }
}
.product-tabs.product-tabs--recette {
    .nav {
        justify-content: flex-start;
    }
    .nav-link.active {
        margin-bottom: 0;
        &::after {
            display: none;
        }
    }
}

.supplier-info-container {
    margin: 40px 0;
}
.supplier-img {
    float: left;
    margin: 0 5% 0 0;
    max-width: 30%;
    @include max-width($small) {
        float: none;
        max-width: 100%;
        margin: 20px 0 20px 0;
    }
}

#contact.layout-left-column {
    #left-column {
        padding-top: 40px;
    }
    .contact-rich {
        @include font-content(16);
    }
}
